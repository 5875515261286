// fonts

$base-font-size: 16px;
$primary: #3775d0;
$primary-inactive: #b8bdc4;
$green: #00B200;
$red: #d94141;
$dark-text: #323d4d;
$footer-bg: #f9f9f9;

// breakpoints

$bp-small: 500px;
$bp-medium: 870px;
$bp-medium-1: 1000px;
$bp-medium-2: 1050px;
$bp-large: 1180px;

// other

$off-canvas-width: 260px;
