.Alert {
    @include rem('font-size', 10px);
    @include rem('padding', 17px 0px);
    color: white;
    text-align: center;
    position: relative;

    @include breakpoint($bp-medium) {
        @include rem('font-size', 13px);
        @include rem('padding', 16px);
    }

    .container {
        padding: 0 25px;
    }
}

.Alert a {
    color: white;
    text-decoration: underline;
}

.Alert--info {
    background: #2c394b;
}

.Alert--info-light {
    background: #64798e;
}

.Alert--success {
    background: #13af81;
}

.Alert--error {
    background: #ca5826;
}

.Alert__close {
    position: absolute;
    top: 19px;
    right: 8px;
    cursor: pointer;

    @include breakpoint($bp-medium) {
        top: 16px;
        right: 20px;
    }

    img {
        @include rem('width', 10px);
        @include rem('height', 10px);
    }
}

.slide-transition {
    transition: all 0.4s ease;
    max-height: 100px;
    overflow: hidden;
}

.slide-leave, .slide-enter {
    max-height: 0px;
    padding: 0px;
    opacity: 0px;
}
