.block-style-1 {
    display: table;
    width:100%;
    ul {
        display: table-row;
        li {
            display:block;
            @include rem('padding-left', 20px);
            @include rem('padding-top', 27px);
            @include rem('padding-bottom', 27px);
            border-left: 1px solid;
            border-top: 1px solid;
            border-right: 1px solid;
            border-bottom: none;
            border-color: #d2d2d2;
            @include breakpoint($bp-medium) {
                display: table-cell;
                border-bottom: 3px solid;
                border-right: none;
                @include rem('padding-top', 26px);
                @include rem('padding-left', 26px);
            }
            @include breakpoint($bp-large) {
                border-color: #d9d9d9;
                @include rem('padding-top', 30px);
                @include rem('padding-bottom', 26px);
            }
            &:last-child {
                border-bottom: 1px solid #d2d2d2;
                border-right: 1px solid #d2d2d2;
                @include breakpoint($bp-medium) {
                    border-bottom: 3px solid;
                }
                @include breakpoint($bp-large) {
                    border-right-color: #d9d9d9;
                }
            }
        }
    }
    &--header {
        color: #323d4d;
        @include rem('font-size', 14px);
        @include breakpoint($bp-medium) {
            @include rem('font-size', 16px);
        }
        @include breakpoint($bp-large) {
            @include rem('font-size', 18px);
        }
    }
    &--content {
        color: #323d4d;
        @include rem('font-size', 40px);
        @include rem('line-height', 40px);
        @include rem('padding-top', 15px);
        @include rem('padding-bottom', 16px);
        @include breakpoint($bp-medium) {
            @include rem('font-size', 43px);
            @include rem('line-height', 41px);
            @include rem('padding-top', 20px);
            @include rem('padding-bottom', 14px);
        }
        @include breakpoint($bp-large) {
            @include rem('font-size', 50px);
            @include rem('line-height', 48px);
            @include rem('padding-top', 20px);
            @include rem('padding-bottom', 16px);
        }
    }
    &--footer {
        @include rem('font-size', 19px);
        @include breakpoint($bp-large) {
            @include rem('font-size', 22px);
        }
        &:before {
            content: "";
            display: block;
            width: 0;
            height: 0;
            border-left: 8.5px solid transparent;
            border-right: 8.5px solid transparent;
            float: left;
            margin-top: 6.5px;
            margin-right:7px;
            @include breakpoint($bp-medium) {
                margin-top: 5.5px;
                border-left: 9.5px solid transparent;
                border-right: 9.5px solid transparent;
            }
            @include breakpoint($bp-large) {
                margin-right:7px;
                margin-top: 6px;
                border-left: 11px solid transparent;
                border-right: 11px solid transparent;
            }
        }
    }
    li.block-style-1--red {
        @include breakpoint($bp-medium) {
            border-bottom-color: #d94141;
            &:last-child {
                border-bottom-color: #d94141;
            }
        }
    }
    li.block-style-1--green {
        @include breakpoint($bp-medium) {
            border-bottom-color: #00b200;
            &:last-child {
                border-bottom-color: #00b200;
            }
        }
    }
    &--red > &--footer:before {
        border-top-color: #d94141;
        border-bottom-color: #d94141;
    }

    &--green > &--footer:before {
        border-top-color: #00b200;
        border-bottom-color: #00b200;
    }

    .arrow-down > &--footer:before {
        border-top-style: solid;
        border-top-width: 8px;
        @include breakpoint($bp-medium) {
            border-top-width: 10px;
        }
        @include breakpoint($bp-large) {
            border-top-width: 11px;
        }
    }
    .arrow-up > &--footer:before {
        border-bottom-style: solid;
        border-bottom-width: 8px;
        @include breakpoint($bp-medium) {
            border-bottom-width: 10px;
        }
        @include breakpoint($bp-large) {
            border-bottom-width: 11px;
        }
    }


    li.block-style-1--grey {
        @include breakpoint($bp-medium) {
            border-bottom-color: #c6c6c6;
            &:last-child {
                border-bottom-color: #c6c6c6;
            }
        }
    }
    li.no-diff {
        .block-style-1--footer {
            padding-left: 0;
        }
    }
    li.not-filled {
        .block-style-1--content {
            color: #999da4;
        }
    }
    &--grey > &--footer:before {
        border-left: none;
        border-right: none;
        margin: 0;
    }
}

.block-style-2 {
    width: 100%;
    display: table;
    table-layout: fixed;
    border-collapse: collapse;
    ul {
        list-style-type: none;
        width: 100%;
        display: inline-block;
        @include breakpoint($bp-medium) {
            display: table-row;
        }
        margin: 0;
        padding: 0;
        li {
            color: #323d4d;
            text-align: center;
            display: block;
            text-align: left;
            border-top: solid 1px #d2d2d2;
            border-left: solid 1px #d2d2d2;
            border-right: solid 1px #d2d2d2;
            @include rem('padding-left', 20px);
            @include rem('padding-top', 25px);
            @include rem('padding-bottom', 25px);
            @include breakpoint($bp-medium) {
                display: table-cell;
                border-bottom: solid #d2d2d2 1px;
                border-right: none;
                @include rem('padding-top', 25px);
                @include rem('padding-bottom', 25px);
            }
            @include breakpoint($bp-large) {
                @include rem('padding-top', 30px);
                @include rem('padding-bottom', 30px);
                border-bottom: solid #d9d9d9 1px;
                border-top: solid 1px #d9d9d9;
                border-left: solid 1px #d9d9d9;
            }

            &:last-child {
                border-right: solid 1px #d2d2d2;
                border-bottom: solid #d2d2d2 1px;
                @include breakpoint($bp-large) {
                    border-right: solid 1px #d9d9d9;
                    border-bottom: solid #d9d9d9 1px;
                }
            }
        }
    }
    &__header {
        @include rem('font-size', 15px);
        @include breakpoint($bp-medium) {
            @include rem('font-size', 16px);
        }
        @include breakpoint($bp-large) {
            @include rem('font-size', 18px);
        }
    }
    &__content {
        @include rem('font-size', 40px);
        @include breakpoint($bp-medium) {
            @include rem('font-size', 52px);
        }
        @include breakpoint($bp-large) {
            @include rem('font-size', 60px);
        }
    }
}

.buy-report-cell , .buy-score-cell{
    .riskscore--block {
        border-bottom: none;
        &__score,
        &__description {
            color: #7a7a7a !important;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            margin-left: 3%;
            margin-right: 3%;
        }
        &__stars {
            li,
            li.active ~ li {
                background: url(/images/company/star-grey.svg) no-repeat;
            }
        }
    }
    .default-table {
        td, th {
            color: #7a7a7a;
        }
    }
    .table {
        .riskscore--cell {
            border-bottom-color: #7a7a7a;
        }
    }
    .credit-limit__block ul li {
        color: #7a7a7a;
    }
    h2 {
        color: #7a7a7a;
        background: url(/images/lock.svg) no-repeat left 2px;
        background-size: 18px 18px;
        padding-left: 32px;
        width: 60%;

        @include breakpoint($bp-medium) {
            width: 100%;
        }
    }
}


.riskscore--block {
    text-align: center;
    @include rem('padding-top', 30px);
    @include rem('padding-bottom', 28px);
    @include breakpoint($bp-medium) {
        @include rem('padding-top', 0px);
        @include rem('padding-bottom', 0px);
    }
    &__score {
        color: #323d4d;
        @include rem('font-size', 53px);
        @include rem('line-height', 53px);
        @include breakpoint($bp-medium) {
            color: #515a66;
            @include rem('font-size', 78px);
            @include rem('line-height', 78px);
        }
        @include breakpoint($bp-large) {
            @include rem('font-size', 90px);
            @include rem('line-height', 90px);
        }
    }
    &__description {
        @include rem('padding-top', 25px);
        @include rem('padding-bottom', 19px);
        @include rem('line-height', 20px);
        @include rem('font-size', 16px);
        @include breakpoint($bp-medium) {
            @include rem('padding-top', 22px);
        }
        @include breakpoint($bp-large) {
            @include rem('font-size', 25px);
        }
    }
    &__stars {
        display: inline-block;
        & ul {
            list-style-type: none;
            height: 36px;
            padding: 0;
            & > li {
                background: url(/images/company/star-full.svg) no-repeat;
                float: left;
                margin: 0 2px;
                @include rem('height', 26px);
                @include rem('width', 26px);
                @include breakpoint($bp-medium) {
                    @include rem('height', 30px);
                    @include rem('width', 30px);
                }
                @include breakpoint($bp-large) {
                    @include rem('height', 35px);
                    @include rem('width', 35px);
                }
            }
        }
        & li.active ~ li {
            background: url(/images/company/star-empty.svg) no-repeat;
        }
        .inactive {
            display: none;
        }
    }
}

.riskscore--cell-active {
    .riskscore--block__description {
        color: #00b200;
    }
}

.riskscore--cell-dissolved {
    .riskscore--block__description {
        color: #d94141;
    }
}

.riskscore--cell-yellow {
    .riskscore--block__description {
        color: #d2ac3a;
    }
}

.riskscore--cell-inactive {
    .riskscore--block__description {
        color: #7a7a7a;
    }
}

.riskscore--cell-not-set {
    .riskscore--block__description {
        color: #C6C6C6;
    }
}

.reviews--block {
    text-align: center;
    border-top: 1px solid #d2d2d2;
    border-bottom: 1px solid #d2d2d2;
    padding-top: 21px;
    padding-bottom: 28px;
    @include breakpoint($bp-medium) {
        @include rem('width', 224px);
    }
    @include breakpoint($bp-large) {
        @include rem('width', 258px);
        border-top: 1px solid #d9d9d9;
        border-bottom: 1px solid #d9d9d9;
    }
    &__header {
        font-size: 16px;
        color: #515a66;
    }
    &__rating {
        color: #323d4d;
        @include rem('padding-top', 19px);
        @include rem('padding-bottom', 23px);
        @include rem('font-size', 53px);
        @include breakpoint($bp-medium) {
            @include rem('padding-top', 18px);
            @include rem('padding-bottom', 24px);
            @include rem('font-size', 78px);
        }
        @include breakpoint($bp-large) {
            @include rem('padding-top', 22px);
            @include rem('padding-bottom', 30px);
            @include rem('font-size', 90px);
        }
        & > small {
            @include rem('font-size', 35px);
            @include breakpoint($bp-medium) {
                @include rem('font-size', 44px);
            }
            @include breakpoint($bp-large) {
                @include rem('font-size', 50px);
            }
        }
    }
    &__footer {
        color: #3775d0;
        text-transform: uppercase;
        @include rem('padding-bottom', 18px);
        @include rem('font-size', 13px);
        @include breakpoint($bp-medium) {
            @include rem('padding-bottom', 26px);
        }
        @include breakpoint($bp-large) {
            @include rem('padding-bottom', 32px);
            @include rem('font-size', 14px);
        }
    }
    &__tick {
        display: inline-block;
        & ul {
            list-style-type: none;
            height: 36px;
            padding: 0;
            & > li {
                background: url(/images/company/reviewtick-full.svg);
                float: left;
                @include rem('width', 36px);
                @include rem('height', 36px);
                @include rem('margin', 0px 2px);
                @include breakpoint($bp-medium) {
                    @include rem('margin', 0px 3px);
                }
            }
        }
        & li.active ~ li {
            background: url(/images/company/reviewtick-empty.svg);
        }
    }
}

.directors--block {
    height: 100%;
    min-height:210px;
    width: 100%;
    display: table;
    table-layout: fixed;
    @include rem('padding-top', 46px);
    @include rem('padding-bottom', 43px);
    @include breakpoint($bp-medium) {
        @include rem('padding-top', 0px);
        @include rem('padding-bottom', 0px);
    }
    ul {
        list-style-type: none;
        display: table-row;
        margin: 0;
        padding: 0;
        width: 100%;
        li {
            color: #323d4d;
            text-align: center;
            list-style: none;
            display: table-cell;
            vertical-align: middle;
            text-align: center;
            word-spacing: 999999px;
            @include breakpoint($bp-medium) {
                color: #515a66;
            }
        }
    }
    &__header {
        @include rem('font-size', 53px);
        @include breakpoint($bp-medium) {
            @include rem('font-size', 60px);
        }
        @include breakpoint($bp-large) {
            @include rem('font-size', 70px);
        }
    }
    &__content {
        @include rem('font-size', 13px);
        @include breakpoint($bp-medium) {
            @include rem('font-size', 16px);
        }
        @include breakpoint($bp-large) {
            @include rem('font-size', 19px);
        }
    }
}

.structure--block {
    height: 100%;
    width: 100%;
    display: table;
    table-layout: fixed;
    @include rem('padding-top', 52px);
    @include rem('padding-bottom', 43px);
    @include breakpoint($bp-medium) {
        @include rem('min-height', 195px);
        @include rem('padding-top', 0px);
        @include rem('padding-bottom', 0px);
    }
    @include breakpoint($bp-large) {
        @include rem('min-height', 225px);
    }
    ul {
        list-style-type: none;
        display: table-row;
        margin: 0;
        padding: 0;
        width: 100%;
        li {
            color: #323d4d;
            text-align: center;
            list-style: none;
            display: table-cell;
            vertical-align: middle;
            text-align: center;
            @include breakpoint($bp-medium) {
                color: #515a66;
            }
        }
    }
    &__header {
        @include rem('font-size', 45px);
        @include breakpoint($bp-medium) {
            @include rem('font-size', 53px);
        }
        @include breakpoint($bp-large) {
            @include rem('font-size', 60px);
        }
    }
    &__content {
        white-space: pre-line;
        @include rem('font-size', 13px);
        @include breakpoint($bp-medium) {
            @include rem('font-size', 16px);
        }
        @include breakpoint($bp-large) {
            @include rem('font-size', 19px);
        }
    }
}

.charges--block {
    color: #454545;
    @include rem('font-size', 13px);
    @include breakpoint($bp-large) {
        @include rem('font-size', 15px);
    }
    h5 {
        border: solid 1px #d2d2d2;
        background: #eaf3ff;
        @include rem('padding-left', 10px);
        @include rem('line-height', 33px);
        @include rem('font-size', 13px);
        @include breakpoint($bp-medium) {
            @include rem('padding-left', 14px);
            @include rem('line-height', 29px);
        }
        @include breakpoint($bp-large) {
            border: solid 1px #d9d9d9;
            @include rem('font-size', 15px);
            @include rem('padding-left', 16px);
            @include rem('line-height', 32px);
        }
    }
    ul {
        list-style-type: none;
        li {
            line-height: 21px;
            padding-top:14px;
            padding-bottom: 12px;
            padding-left: 10px;
            padding-right: 10px;
            & > span {
                float: right;
            }
            &:nth-child(even) {
                background: #f4f4f4;
            }
            &:before {
                border-radius: 50%;
                float: left;
                width:13px;
                height:13px;
                margin: 4px 0;
                margin-right: 8px;
                display:block;
                content: " ";
            }
            &.outstanding-judgements:before {
                background:#d94141;
            }
            &.satisfied-judgements:before {
                background:#c8a620;
            }
            &.outstanding-mortgages:before {
                background:#c8a620;
            }
            &.satisfied-mortgages:before {
                background:#00b200;
            }
        }
    }
}

.charges--advert {
    @extend .charges--block;
    ul {
        li {
            color: #7a7a7a;
            &.outstanding-judgements:before {
                background:#8c8c8c;
            }
            &.satisfied-judgements:before {
                background:#b4b4b4;
            }
            &.outstanding-mortgages:before {
                background:#b4b4b4;
            }
            &.satisfied-mortgages:before {
                background:#878787;
            }
        }
    }
}

.info-table {
    color:#454545;
    @include rem('font-size', 13px);
    @include rem('line-height', 19px);
    @include breakpoint($bp-medium) {
        @include rem('font-size', 14px);
        @include rem('line-height', 21px);
    }
    @include breakpoint($bp-large) {
        @include rem('font-size', 16px);
        @include rem('line-height', 24px);
    }
    strong {
        margin: 0;
        display: block;
        width: 100%;
        font-weight: bold;
        &:first-child {
            padding-top: 0;
        }
    }
    .table-cell {
        vertical-align: top;
        border-bottom: solid 1px #d2d2d2;
        @include rem('padding-top', 11px);
        @include rem('padding-bottom', 11px);
        @include breakpoint($bp-medium) {
            width: 50%;
            @include rem('padding-top', 14px);
            @include rem('padding-bottom', 12px);
        }
        @include breakpoint($bp-large) {
            @include rem('padding-top', 17px);
            @include rem('padding-bottom', 14px);
            border-bottom: solid 1px #d9d9d9;
        }
    }
    .table-row:last-child {
        .table-cell {
            border-bottom: none;
        }
    }

}

.info-block {
    color:#454545;
    display: block;
    @include rem('font-size', 13px);
    @include rem('line-height', 19px);
    @include breakpoint($bp-medium) {
        @include rem('font-size', 14px);
        @include rem('line-height', 21px);
    }
    @include breakpoint($bp-large) {
        @include rem('font-size', 16px);
        @include rem('line-height', 24px);
    }
    dl {
        margin: 0;
        display: block;
        width: 100%;
        overflow: auto;

        dt {
            font-weight: bold;
            @include rem('padding-top', 11px);
            @include breakpoint($bp-medium) {
                @include rem('padding-top', 14px);
            }
            @include breakpoint($bp-large) {
                @include rem('padding-top', 17px);
            }
            &:first-child {
                padding-top: 0;
            }
        }
        dd {
            margin: 0;
            border-bottom: solid 1px #d2d2d2;
            @include rem('padding-bottom', 11px);
            @include breakpoint($bp-medium) {
                @include rem('padding-bottom', 12px);
            }
            @include breakpoint($bp-large) {
                @include rem('padding-bottom', 14px);
                border-bottom: solid 1px #d9d9d9;
            }
            &:last-child {
                border-bottom: none;
                padding-bottom: 0;
            }
        }
    }
    &--columns {
        @include breakpoint($bp-medium) {
            -webkit-column-count: 2;
            -moz-column-count: 2;
            column-count: 2;

            break-inside: avoid-column;
        -webkit-column-break-inside: avoid;
            overflow: auto;
            @include rem('-webkit-column-gap', 40px);
            @include rem('-moz-column-gap', 40px);
            @include rem('column-gap', 40px);
        }
        @include breakpoint($bp-large) {
            @include rem('-webkit-column-gap', 48px);
            @include rem('-moz-column-gap', 48px);
            @include rem('column-gap', 48px);
        }
    }
    .social-images {
        display: none;

        @include breakpoint($bp-medium) {
            display: block;
            position: absolute;
            top: 5px;
            right: 0px;
        }

        img {
            height: 32px;
            width: 32px;
            margin: 0 3px;
            @include breakpoint($bp-large) {
                height: 37px;
                width: 37px;
            }
        }
    }
}

.buy-now {
    width: 100%;
    background: #f2fbf2;
    display: table;
    padding-left:10px;
    line-height: 28px;
    @include rem('padding-top', 13px);
    @include rem('padding-bottom', 13px);
    @include breakpoint($bp-medium) {
        display: block;
        @include rem('padding-top', 15px);
        @include rem('padding-bottom', 15px);
    }
    div {
        display: table-cell;
        margin-right:15px;
        vertical-align: middle;
    }
    p {
        color: #00b200;
        margin-left:15px;
        display: inline-block;
        @include rem('line-height', 17px);
        @include rem('font-size', 13px);
        @include breakpoint($bp-medium) {
            @include rem('font-size', 16px);
        }

    }

    .not-logged-in {
        color: #515a66;
        margin-left:15px;
        display: inline-block;
        @include rem('line-height', 17px);
        @include rem('font-size', 13px);
        @include breakpoint($bp-medium) {
             @include rem('font-size', 16px);
        }
    }
    .buy-now-button {
        text-align: center;
        font-size:12px;
        display: inline-block;
        border-radius: 4px;
        width: 99px;
        height: 27px;
        color: #ffffff;
        background: #00b200;
    }
}

.about-block {
    p {
        color:#454545;
        @include rem('font-size', 13px);
        @include rem('line-height', 22px);
        @include rem('padding-bottom', 21px);
        @include breakpoint($bp-medium) {
            @include rem('font-size', 16px);
            @include rem('line-height', 26px);
        }
        &:last-child {
            padding-bottom: 0;
        }
    }
}

.address--block {
    &__title {
        color: #737373;
        display: block;
        font-weight: 500;
        @include rem('font-size', 16px);
        @include rem('padding-bottom', 16px);
        @include breakpoint($bp-medium) {
            font-weight: 300;
            @include rem('font-size', 18px);
            @include rem('padding-bottom', 18px);
        }
        @include breakpoint($bp-large) {
            @include rem('font-size', 20px);
            @include rem('padding-bottom', 22px);
        }
    }
    &__address {
        color: #494949;
        @include rem('line-height', 19px);
        @include rem('font-size', 13px);
        @include rem('padding-top', 14px);
        @include breakpoint($bp-medium) {
            @include rem('line-height', 23px);
            @include rem('font-size', 14px);
            @include rem('padding-top', 15px);
        }
        @include breakpoint($bp-large) {
            @include rem('line-height', 24px);
            @include rem('font-size', 16px);
            @include rem('padding-top', 18px);
        }
        strong {
            display: block;
        }
    }
}

.document-filters {
    background: white;
    line-height: 35px;
    border:#c9c9c9 1px solid;
    position: absolute;
    top:37px;
    right:0;
    z-index:2;
    min-width: 13em;
    .active {
        background: url(/images/share-page.svg) 5px center no-repeat;
        background-size: 20px 20px;
    }
    li {
        padding-left: 30px;
        padding-right:10px;
        border-bottom:#d2d2d2 1px solid;
        @include breakpoint($bp-large) {
            border-bottom:#d9d9d9 1px solid;
        }
        &:last-child {
            border-bottom:none;
        }
        span {
            float:right;
            margin-left: 10px;
        }
    }
    li:hover {
        background-color: #eaf3ff;
    }
}
    .middle--row--right-buy {
        position: absolute;
        margin-left: 15px;
    }
    .middle--row--right-watch {
        position: absolute;
        margin-left: 185px;
        .watch-button-dropdown {
            @extend .document-filters;
            right: 20px;
            top: 37px;
            @include breakpoint($bp-large) {
                top: 43px;
            }
            color: #494949;
            li {
                text-align: left;
            }
        }
    }

    .mobile-watch-button {
        @extend .document-filters;
        color: #494949;
        position:relative;

    }


.credit-limit__block {
    width: 100%;
    display: table;
    table-layout: fixed;
    border-spacing: 0;
    vertical-align: middle;
    ul {
        display: table-row;
        margin: 0;
        padding: 0;
        width: 100%;
        li {
            color: #323d4d;
            text-align: left;
            border-left: solid #d2d2d2 1px;
            border-top: solid #d2d2d2 1px;
            border-right: solid #d2d2d2 1px;
            @include rem('padding-left', 25px);
            @include rem('padding-top', 25px);
            @include rem('padding-bottom', 25px);
            @include breakpoint($bp-medium) {
                display: table-cell;
                border-right: none;
                border-bottom: 1px solid #d2d2d2;
            }
            @include breakpoint($bp-large) {
                @include rem('padding-top', 30px);
                @include rem('padding-bottom', 30px);
                border-left: solid #d9d9d9 1px;
                border-top: solid #d9d9d9 1px;
                border-bottom: 1px solid #d9d9d9;
            }
            &:last-child {
                border-bottom: 1px solid #d2d2d2;
                @include breakpoint($bp-medium) {
                    border-right: 1px solid #d2d2d2;
                }
                @include breakpoint($bp-large) {
                    border-right: 1px solid #d9d9d9;
                }
            }
        }
    }
    &__header {
        @include rem('font-size', 16px);
        @include rem('line-height', 16px);
        @include rem('padding-bottom', 20px);
        @include breakpoint($bp-large) {
            @include rem('padding-bottom', 23px);
            @include rem('line-height', 18px);
            @include rem('font-size', 18px);
        }
    }
    &__content {
        @include rem('font-size', 52px);
        @include rem('line-height', 52px);
        @include breakpoint($bp-large) {
            @include rem('line-height', 60px);
            @include rem('font-size', 60px);
        }
    }
}

.fb-comments{
    width:100%;
    span {
        width:100%;
        .fb_ltr {
            width:100%;
        }
    }
}
