.Tooltip {
    @include rem('padding', 5px);
    @include rem('font-size', 14px);
    background: white;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    text-align: center;
    position: absolute;
    -webkit-box-shadow: 3px 3px 10px 0px rgba(0,0,0,0.4);
    -moz-box-shadow: 3px 3px 10px 0px rgba(0,0,0,0.4);
    box-shadow: 3px 3px 10px 0px rgba(0,0,0,0.4);
}

.Tooltip--hide {
    display: none;
}

.Tooltip--show {
    display: block;
}

.Tooltip--bottom-arrow {
    &:after, &:before {
        top: 100%;
        left: 50%;
        border: solid transparent;
        content: " ";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
    }

    &:after {
        border-color: rgba(255, 255, 255, 0);
        border-top-color: #ffffff;
        border-width: 5px;
        margin-left: -5px;
    }
    &:before {
        border-color: rgba(217, 217, 217, 0);
        border-top-color: #d9d9d9;
        border-width: 6px;
        margin-left: -6px;
    }
}