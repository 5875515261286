html,
body,
.outer-container,
.transformer {
    height: 100%;
    background: #fff;
}

.fixed {
    position: fixed;
}

.transformer {
    -webkit-transition: 200ms ease-in-out;
    -moz-transition: 200ms ease-in-out;
    -o-transition: 200ms ease-in-out;
    transition: 200ms ease-in-out;
}

.transformer.is-open {
    -webkit-transform: translateX($off-canvas-width);
    -ms-transform: translateX($off-canvas-width);
    transform: translateX($off-canvas-width);
    position: fixed;
    width: 100%;
}

.nav--off-canvas {
    height: 100%;
    overflow-y: scroll;
    position: absolute;
    width: $off-canvas-width;
    left: -$off-canvas-width;
    top: 0;
    bottom: 0;
    background: #333;
    color: white;
    li {
        @include rem('padding', 0px 15px);
        width: 100%;
        display: block;
        width: 100%;
        position: relative;
    }
    .item__title {
        @include rem('line-height', 50px);
        @include rem('font-size', 15px);
        @include rem('padding-left', 28px);
        font-weight: 400;
        text-decoration: none;
        color: white;
        display: block;
    }
    .item__image {
        @include rem('height', 14px);
        @include rem('width', 14px);
        @include rem('top', 17px);
        position: absolute;
    }
    .login-section {
        @include cf;
        @include rem('padding', 12px 25px);
        background: #606060;
        .log-in-link {
            @include rem('font-size', 15px);
            @include rem('padding-top', 7px);
            font-weight: 400;
            text-decoration: none;
            color: white;
            float: left;
        }
        .sign-up-link {
            @include rem('padding', 8px 35px);
            @include rem('font-size', 13px);
            float: right;
        }
    }
    .contact-number {
        @include rem('font-size', 15px);
        @include rem('margin-top', 30px);
        color: #B6B6B6;
        text-align: center;
    }
}

.container {
    @include container(1180px);
    padding: 0 20px;
    @include breakpoint($bp-medium) {
        @include show-grid(10);
        padding: 0 55px;
    }
    @include breakpoint($bp-large) {
        @include show-grid(16);
        padding: 0 64px;
    }
}

.content {
    @include header-offset;
}

.content--floating-header:before {
    padding-top: 0px;
}

.clearfix {
    clear: both;
}

.hide {
    @include hide;
}


/* VISIBILITY */

.visibility--mobile {
    display: block !important;
    @include breakpoint($bp-medium) {
        display: none !important;
    }
}

.visibility--tablet {
    display: none !important;
    @include breakpoint($bp-medium) {
        display: initial !important;
    }
}

.visibility--desktop {
    display: none !important;
    @include breakpoint($bp-large) {
        display: initial !important;
    }
}

.banner-ad {
    text-align: center;
}

.ad-extra-padding {
    @include rem('margin-bottom', 50px);
    @include breakpoint($bp-medium) {
        @include rem('margin-bottom', 70px);
    }
}

.square-ad {
    @include rem('width', 300px);
    margin: 0 auto;
}

.streamamp-advert {
    @include rem('margin-top', 30px);
    @include rem('margin-bottom', 30px);
    text-align: center;
    padding: 0px;
    * {
        margin: 0 auto !important; // overwrite inline ad styles
    }
    img {
        width: initial;
    }
    @include breakpoint($bp-small) {
        padding: 0 20px;
    }
}

.news-bar {
    @include rem('font-size', 13px);
    @include rem('padding', 17px 0px);
    @include rem('line-height', 18px);
    position: relative;
    width: 100%;
    background: #475262;
    color: white;
    text-align: center;
    display: none;
    p {
        @include breakpoint($bp-medium) {
            width: 80%;
            float: left;
        }
    }
    a {
        color: white;
        text-decoration: underline;
    }
    @include breakpoint($bp-large) {
        @include rem('font-size', 14px);
        @include rem('line-height', 23px);
    }
    &.open {
        display: block;
    }
}

.pull-left {
    float: left !important;
}

.pull-right {
    float: right !important;
}

.remove-ads-link {
    margin-top: 8px !important;
    text-decoration: underline;
    color: #6E737C;
    display: block;
}

[v-cloak] {
    display: none;
}

.js-loading-container {
    display: flex;
    align-items: center;
    justify-content: center;
}