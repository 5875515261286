[data-control],
[data-content] > * {
    border-bottom: none;
    height: inherit;
    line-height: inherit;
}

[data-control] {
    @include rem('padding', 0px 15px 0px 28px);
}

[data-content] li {
    @include rem('padding-left', 28px);

    a {
        @include rem('line-height', 50px);
        @include rem('font-size', 15px);
        color: white;
        text-decoration: none;
    }
}

[data-accordion].open [data-control] {
    border-bottom: 1px solid #8c8c8c;
}

[data-accordion] > [data-control]:after {
    top: 18px;
    right: 3px;
    background: url('../../../images/arrow-right.svg') center center no-repeat;
    width: 11px;
    height: 11px;
}

[data-accordion].open > [data-control]:after {
    -webkit-transform: rotate(+90deg);
    -ms-transform: rotate(+90deg);
    transform: rotate(+90deg);
}
