.table-container {
    overflow-x: auto;
}

.directors-table,
.reports-table {
    tbody {
        td {
            &:first-child {
                white-space: normal;
                text-overflow: clip;
            }
        }
    }
}

.claim-director-table {
    a {
        text-decoration: none;
        color: $primary;
    }
}

.nowrap {
    white-space: nowrap;
}

.fixed-column {
    position: absolute;
    display: inline-block;
    width: auto;
    thead,
    tbody {
        border-right: solid 1px #d2d2d2;
        @include breakpoint($bp-large) {
            border-right: solid 1px #d9d9d9;
        }
    }
    @include breakpoint($bp-medium) {
        display: none;
    }
}

.shareholdings-table {
    tbody {
        td {
            text-align: left;
        }
    }
}

.dtr-title {
    display: inline-block;
    font-weight: bold;
    min-width: 75px;
}

tr.child {
    border-top: solid 1px #d2d2d2;
    border-bottom: solid 1px #d2d2d2;
}

table.dataTable {
    &.dtr-inline.collapsed>tbody>tr>td:first-child,
    &.dtr-inline.collapsed>tbody>tr>th:first-child {
        position: relative;
        padding-left: 30px;
        cursor: pointer;
    }
    &.dtr-inline.collapsed>tbody>tr>td:first-child:before,
    &.dtr-inline.collapsed>tbody>tr>th:first-child:before {
        top: 14px;
        left: 4px;
        height: 16px;
        width: 16px;
        display: block;
        position: absolute;
        color: white;
        border: 2px solid white;
        border-radius: 16px;
        text-align: center;
        line-height: 16px;
        box-shadow: 0 0 3px #444;
        box-sizing: content-box;
        content: '+';
        background-color: #31b131;
    }
    &.dtr-inline.collapsed>tbody>tr>td:first-child.dataTables_empty:before,
    &.dtr-inline.collapsed>tbody>tr>th:first-child.dataTables_empty:before {
        display: none;
    }
    &.dtr-inline.collapsed>tbody>tr.parent>td:first-child:before,
    &.dtr-inline.collapsed>tbody>tr.parent>th:first-child:before {
        content: '-';
        line-height: 14px;
        background-color: #d33333;
    }
    &.dtr-inline.collapsed>tbody>tr.child td:before {
        display: none;
    }
    &.dtr-inline.collapsed.compact>tbody>tr>td:first-child,
    &.dtr-inline.collapsed.compact>tbody>tr>th:first-child {
        padding-left: 27px;
    }
    &.dtr-inline.collapsed.compact>tbody>tr>td:first-child:before,
    &.dtr-inline.collapsed.compact>tbody>tr>th:first-child:before {
        top: 5px;
        left: 4px;
        height: 14px;
        width: 14px;
        border-radius: 14px;
        line-height: 12px;
    }
    &.dtr-column>tbody>tr>td.control,
    &.dtr-column>tbody>tr>th.control {
        position: relative;
        cursor: pointer;
    }
    &.dtr-column>tbody>tr>td.control:before,
    &.dtr-column>tbody>tr>th.control:before {
        top: 50%;
        left: 50%;
        height: 16px;
        width: 16px;
        margin-top: -10px;
        margin-left: -10px;
        display: block;
        position: absolute;
        color: white;
        border: 2px solid white;
        border-radius: 16px;
        text-align: center;
        line-height: 16px;
        box-shadow: 0 0 3px #444;
        box-sizing: content-box;
        content: '+';
        background-color: #31b131;
    }
    &.dtr-column>tbody>tr.parent td.control:before,
    &.dtr-column>tbody>tr.parent th.control:before {
        content: '-';
        line-height: 14px;
        background-color: #d33333;
    }
}

.default-table {
    width: 100%;
    table-layout: auto;
    @include rem('font-size', 13px);
    @include breakpoint($bp-large) {
        @include rem('font-size', 15px);
    }
    time {
        white-space: nowrap;
    }
    td,
    th {
        color: #454545;
        line-height: 21px;
        padding-left: 10px;
        padding-right: 10px;
        text-align: right;
        &:first-child {
            text-align: left;
        }
    }
    td {
        padding-top: 14px;
        padding-bottom: 12px;
        text-align: right;
        &:first-child {
            text-align: left;
            // white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
    thead {
        width: 100%;
        line-height: 33px;
        background: #eaf3ff;
        border-top: solid 1px #d2d2d2;
        border-bottom: solid 1px #d2d2d2;
        @include breakpoint($bp-large) {
            border-top: solid 1px #d9d9d9;
            border-bottom: solid 1px #d9d9d9;
        }
        background: #eaf3ff;
        th {
            line-height: 33px;
            padding-right: 19px;
            white-space: nowrap;
            &:first-child {
                border-left: solid 1px #d2d2d2;
                @include breakpoint($bp-large) {
                    border-left: solid 1px #d9d9d9;
                }
            }
            &:last-child:not(:first-child) {
                border-right: solid 1px #d2d2d2;
                @include breakpoint($bp-large) {
                    border-right: solid 1px #d9d9d9;
                }
            }
        }
    }
    tbody {
        th {
            padding-top: 14px;
            padding-bottom: 12px;
            text-overflow: ellipsis;
            overflow: hidden;
            max-width: 180px;
            @include breakpoint($bp-small) {
                max-width: none;
                text-overflow: clip;
                overflow: auto;
            }
        }
        tr:nth-child(even) {
            background: #f4f4f4;
        }
        tr:nth-child(odd) {
            background: white;
        }
        tr:last-child {
            border-bottom: 1px solid #d2d2d2;
            @include breakpoint($bp-large) {
                border-bottom: solid 1px #d9d9d9;
            }
        }
    }
}

.nowrap-table-cells {
    tbody {
        td:not(:first-child) {
            white-space: nowrap;
        }
    }
}

.nowrap-table-headers {
    tbody {
        td:first-child,
        th {
            max-width: 180px;
            @include breakpoint($bp-small) {
                max-width: none;
                text-overflow: clip;
                overflow: initial;
            }
        }
    }
}

.nowrap-table-cells {
    tbody {
        td {
            white-space: nowrap;
        }
    }
}

.truncate {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.left-align {
    text-align: left !important;
}

.right-align {
    text-align: right;
}

.center-align {
    text-align: center !important;
}

.fixed-table {
    table-layout: fixed;
}

.table-style-1 {
    td,
    th {
        text-align: left;
    }
}

.table-style-2 {
    th {
        text-align: left;
        border-right: solid 1px #d2d2d2;
        padding-right: 12px;
        @include breakpoint($bp-large) {
            border-right: solid 1px #d9d9d9;
        }
    }
    td {
        text-align: left;
    }
    tbody {
        tr:last-child {
            border-bottom: 1px solid #d2d2d2;
            @include breakpoint($bp-large) {
                border-bottom: 1px solid #d9d9d9;
            }
        }
    }
}

.table-style-3 {
    thead th {
        text-align: right;
    }
    thead th:first-child,
    tbody th {
        text-align: left;
        padding-right: 12px;
    }
    thead th:not(:last-child),
    td:not(:last-child) {
        display: none;
        @include breakpoint($bp-medium) {
            display: table-cell;
        }
    }
    thead th:first-child {
        display: table-cell;
    }
    td {
        text-align: right;
    }
    tr:last-child {
        border-bottom: 1px solid #c2c2c2;
    }
}

.table-style-4 {
    table-layout: auto;
    thead th {
        text-align: left;
    }
    td {
        text-align: right;
        &:first-child {
            text-align: left;
        }
    }
    tr:last-child {
        border-bottom: 1px solid #c2c2c2;
    }
}

.documents-table {
    width: 100%;
    tbody {
        text-align: left;
    }
    th,
    td {
        text-align: left;
    }
    &--price {
        @include breakpoint($bp-medium) {
            width: 23%;
        }
        @include breakpoint($bp-large) {
            width: 20%;
        }
        img {
            display: inline-block;
            float: left;
            height: 18px;
            width: 19px;
            @include breakpoint($bp-medium) {
                height: 16px;
                width: 17px;
                float: right;
            }
        }
    }
}

.company-documents {
    .dataTables_scrollBody {
        thead {
            display: none;
        }
    }
}

.data-table-container {
    .DTFC_LeftHeadWrapper,
    .DTFC_LeftBodyWrapper {
        border-right: solid 1px #d2d2d2;
        @include breakpoint($bp-medium) {
            border-right: none;
        }
        @include breakpoint($bp-large) {
            //border-right: solid 1px #d9d9d9;
        }
    }
    .no-financials--director {
        @include rem('margin-left', 10px);
    }
}

.DTFC_LeftBodyWrapper {
    .DTFC_Cloned {
        thead {
            border: none;
        }
    }
}

.DTFC_Cloned {
    thead {
        border-right: none;
    }
}

.dataTables_scrollBody {
    thead {
        border: none;
    }
}

.dataTables_scrollHead {
    border-right: solid 1px #d2d2d2;
    @include breakpoint($bp-medium) {
        border-right: none;
    }
    thead {
        th {
            &:last-child:not(:first-child) {
                border-right: none;
                @include breakpoint($bp-medium) {
                    border-right: solid 1px #d2d2d2;
                }
                @include breakpoint($bp-large) {
                    border-right: solid 1px #d9d9d9;
                }
            }
        }
    }
}

.related-director__secretaries th:nth-child(even),
.related-director__secretaries td:nth-child(even) {
    text-align: left;
}

@include breakpoint($bp-small) {
    .related-director__secretaries th,
    .related-director__secretaries td {
        text-align: left;
        white-space: nowrap;
    }
}

.accounts-table-div {
    overflow-x: scroll;
}