input[type="search"], input[type="text"], input[type="submit"], input[type="email"], .recurly-hosted-field {
    -webkit-appearance: none;
    border-radius: 0;
}

input[type="text"]:focus, input[type="search"]:focus, input[type="submit"]:focus, , .recurly-hosted-field:focus {
    border: 1px solid #d2d2d2;
    box-shadow: 0 0 0 1pt $primary;
    outline: none;
}

.form-row {
    @include cf;
    display: block;
    width: 100%;
}

.checkbox-option {
    label {
        @include rem('padding-left', 7px);
        @include rem('font-size', 15px);

        @include breakpoint($bp-medium) {
            @include rem('padding-left', 8px);
            @include rem('font-size', 13px);
        }

        @include breakpoint($bp-large) {
            @include rem('font-size', 15px);
        }
    }
}

.form-detail-container {
    @include cf;
}

form {
    .error-message {
        @include rem('font-size', 12px);
        font-weight: 500;
        color: #d03737;

        @include breakpoint($bp-small) {
            @include rem('font-size', 14px);
            font-weight: 400;
        }

        @include breakpoint($bp-large) {
            @include rem('font-size', 16px);
        }
    }

    .error,
    input[type="text"].error,
    input[type="email"].error,
    input[type="password"].error {
        border: 1px solid #ff6565;
    }

    .error:focus,
    input[type="text"].error:focus,
    input[type="email"].error:focus,
    input[type="password"].error:focus {
        border: 1px solid #d2d2d2;
    }

    .success {
        border: 1px solid #00B200;
        box-shadow: 0 0 0 1pt #d2d2d2;
        outline: none;
    }
}

.form-detail {
    position: relative;
    @include rem('margin-bottom', 22px);

    label {
        @include rem('font-size', 13px);
        color: #848990;
        display: inline-block;
    }

    input[type="text"],
    input[type="password"],
    input[type="email"],
    input[type="date"],
    input[type="number"],
    textarea,
    select {
        @include rem('margin-top', 12px);
        @include rem('height', 44px);
        @include rem('padding', 10px);
        @include rem('font-size', 14px);
        display: block;
        width: 100%;
        border: 1px solid #d2d2d2;
        border-radius: 4px;
        background: #f3f3f3;

        &.disabled-input {
            background: white;
        }
    }

    textarea {
        @include rem('height', 135px);
    }
}

.form-left {
    @include breakpoint($bp-medium) {
        @include span(4 of 8);
    }
}

.form-right {
    position: relative;

    @include breakpoint($bp-medium) {
        @include span(4 of 8 last);
    }
}

.submit-button {
    @include rem('margin-top', 23px);
    @include rem('font-size', 14px);
    display: block;
    text-align: center;

    @include breakpoint($bp-medium) {
        @include rem('max-width', 210px);
        @include rem('margin-top', 30px);
        @include rem('font-size', 13px);
    }

    @include breakpoint($bp-large) {
        @include rem('max-width', 242px);
        @include rem('margin-top', 38px);
        @include rem('font-size', 15px);
    }
}

.sign-up-box form {

    @include rem('max-width', 400px);
    margin: 0 auto;
    position: relative;
    height: 40px;
    margin-bottom: 0;

    input[type="email"] {
        @include rem('padding-left', 8px);
        @include rem('padding-right', 95px);
        @include rem('font-size', 13px);
        width: 100%;
        height: 100%;
        display: block;
        border: 1px solid #d5d5d5;
        -webkit-border-radius: 4px;
        -moz-border-radius: 4px;
        border-radius: 4px;
        box-sizing: border-box;

        @include breakpoint($bp-medium) {
            @include rem('font-size', 16px);
        }

        @include breakpoint($bp-large) {
            @include rem('font-size', 18px);
        }
    }

    .sign-up-button {

        @include rem('line-height', 40px);
        @include rem('font-size', 13px);
        @include rem('padding', 0px 20px);
        text-decoration: none;
        color: white;
        border: 0;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        background: $green;

        -webkit-border-top-right-radius: 4px;
        -webkit-border-bottom-right-radius: 4px;
        -moz-border-radius-topright: 4px;
        -moz-border-radius-bottomright: 4px;
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;

        @include breakpoint($bp-medium) {
            @include rem('font-size', 16px);
            @include rem('line-height', 46px);
        }

        @include breakpoint($bp-large) {
            @include rem('font-size', 18px);
        }
    }

    @include breakpoint($bp-medium) {
        @include rem('height', 46px);
    }
}

