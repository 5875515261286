.Four-financials {
    @include rem('margin-bottom', 15px);
    display: table;
    width: 100%;
    color: $dark-text;
    table-layout: fixed;
    @include breakpoint($bp-medium) {
        @include rem('margin-bottom', 40px);
        border-collapse: collapse;
    }
    @include breakpoint($bp-large) {
        @include rem('margin-bottom', 50px);
    }
}

.Four-financial {
    @include rem('padding', 15px);
    @include rem('margin-bottom', 15px);
    display: block;
    border: 1px solid #d2d2d2;
    border-bottom: 3px solid;
    font-weight: 300;
    @include breakpoint($bp-small) {
        width: 49%;
        float: left;
        &:nth-child(2n) {
            float: right;
        }
    }
    @include breakpoint($bp-medium) {
        display: table-cell;
        width: initial;
        float: none;
        &:nth-child(2n) {
            float: none;
        }
    }
}

.Four-financial--red {
    border-bottom-color: $red;
}

.Four-financial--green {
    border-bottom-color: $green;
}

.Four-financial--grey {
    border-bottom-color: #c6c6c6;
}

.Four-financial--no-diff {
    .Four-financial__change {
        padding-left: 0;
    }
}

.Four-financial--not-filled {
    .Four-financial__figure {
        color: #999da4;
    }
}

.Four-financial__header {
    @include rem('font-size', 14px);
    @include breakpoint($bp-medium) {
        @include rem('font-size', 16px);
    }
    @include breakpoint($bp-large) {
        @include rem('font-size', 18px);
    }
}

.Four-financial__figure {
    @include rem('font-size', 40px);
    @include rem('line-height', 40px);
    @include rem('padding', 10px 0px);
    @include breakpoint($bp-medium) {
        @include rem('font-size', 43px);
        @include rem('line-height', 41px);
        @include rem('padding', 12px 0px);
    }
    @include breakpoint($bp-large) {
        @include rem('font-size', 50px);
        @include rem('line-height', 48px);
    }
}

.Four-financial__change {
    @include rem('font-size', 19px);
    @include breakpoint($bp-large) {
        @include rem('font-size', 22px);
    }
    &:before {
        content: "";
        display: block;
        width: 0;
        height: 0;
        border-left: 8.5px solid transparent;
        border-right: 8.5px solid transparent;
        float: left;
        margin-top: 6.5px;
        margin-right: 7px;
        @include breakpoint($bp-medium) {
            margin-top: 5.5px;
            border-left: 9.5px solid transparent;
            border-right: 9.5px solid transparent;
        }
        @include breakpoint($bp-large) {
            margin-right: 7px;
            margin-top: 6px;
            border-left: 11px solid transparent;
            border-right: 11px solid transparent;
        }
    }
}

.Four-financial--red>.Four-financial__change:before {
    border-top-color: #d94141;
    border-bottom-color: #d94141;
}

.Four-financial--green>.Four-financial__change:before {
    border-top-color: #00b200;
    border-bottom-color: #00b200;
}

.Four-financial--arrow-down>.Four-financial__change:before {
    border-top-style: solid;
    border-top-width: 8px;
    @include breakpoint($bp-medium) {
        border-top-width: 10px;
    }
    @include breakpoint($bp-large) {
        border-top-width: 11px;
    }
}

.Four-financial--arrow-up>.Four-financial__change:before {
    border-bottom-style: solid;
    border-bottom-width: 8px;
    @include breakpoint($bp-medium) {
        border-bottom-width: 10px;
    }
    @include breakpoint($bp-large) {
        border-bottom-width: 11px;
    }
}

.Four-financial--grey>.Four-financial__change:before {
    border-left: none;
    border-right: none;
    margin: 0;
}