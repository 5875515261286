//////////////// General ////////////////
header {
    position: fixed;
    top: 0px;
    right: 0;
    left: 0;
    background: white;
    -webkit-box-shadow: 0px 0px 7px 0px rgba(50, 50, 50, 0.32);
    -moz-box-shadow: 0px 0px 7px 0px rgba(50, 50, 50, 0.32);
    box-shadow: 0px 0px 7px 0px rgba(50, 50, 50, 0.32);
    @include rem('height', 50px);
    z-index: 6;
    @include breakpoint($bp-small) {
        @include rem('height', 60px);
    }
    @include breakpoint($bp-medium) {
        border-bottom: none;
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none;
    }
    @include breakpoint($bp-large) {
        @include rem('height', 70px);
    }
}

.header--bottom-border {
    border-bottom: 1px solid #dfdfdf;
    -webkit-box-shadow: 0px 0px 7px 0px rgba(50, 50, 50, 0.32);
    -moz-box-shadow: 0px 0px 7px 0px rgba(50, 50, 50, 0.32);
    box-shadow: 0px 0px 7px 0px rgba(50, 50, 50, 0.32);
    @include breakpoint($bp-medium) {
        border-bottom: 1px solid #d6e1ed;
    }
}

.header {
    @include cf-override;
    display: table;
    width: 100%;
    height: 100%;
    margin: 0 auto;
    padding: 0;
    @include breakpoint($bp-medium) {
        @include rem('padding', 0px 55px);
    }
    @include breakpoint($bp-large) {
        @include rem('padding', 0px 64px);
    }
}

@include breakpoint($bp-medium) {
    .floating-header {
        background: transparent;
        border-bottom: none;
        box-shadow: none;
        position: absolute;
        top: 15px;
        .header__logo a {
            background: url("/images/cocheck-logo-white.svg") no-repeat 0px 0px;
        }
        .nav--header .log-in-link {
            color: white;
        }
        .nav--header .sign-in-link {
            background: rgba(255, 255, 255, 0.2);
        }
        .nav--header .dashboard-icon {
            background: url('/images/activity_white.svg') no-repeat center;
        }
        .nav--header .account-icon {
            background: url('/images/user_white.svg') no-repeat center;
        }
        .nav--header .cart-icon {
            background: url('/images/cart_white.svg') no-repeat center;
        }
        .nav--header .international-icon {
            background: url('/images/globe_white.svg') no-repeat center;
            background-size: cover;
        }
    }
}

.header-section {
    display: table-cell;
    vertical-align: middle;
    text-align: center;
    @include breakpoint($bp-medium) {
        text-align: left;
    }
}

//////////////// Menu Button ////////////////
.header__menu-button {
    background: url('/images/menu_icon.svg') center no-repeat;
    @include rem('background-size',
    21px);
    @include rem('width',
    49px);
    border-right: 1px solid #ccc;
    @include breakpoint($bp-small) {
        @include rem('width',
        60px);
    }
    @include breakpoint($bp-medium) {
        @include hide;
    }
}

//////////////// Logo ////////////////
.header__logo {
    display: none;
    @include breakpoint($bp-medium) {
        display: table-cell;
        @include rem('width',
        228px);
    }
}

.header__logo .logo {
    @include rem('height',
    23px);
    @include rem('width',
    225px);
    @include rem('padding-left',
    26px);
    margin: 0 auto;
    @include breakpoint($bp-medium) {
        margin: 0;
        padding: 0;
        @include rem('height',
        24px);
    }
    @include breakpoint($bp-large) {
        @include rem('height',
        26px);
    }
}

.header__logo-display-override {
    display: table-cell;
}

.header__logo a {
    background: url('/images/CoCheck Logo.svg') no-repeat -26px 0px;
    height: 100%;
    display: block;
    @include breakpoint($bp-medium) {
        background: url('/images/CoCheck Logo.svg') no-repeat 0px 0px;
    }
}

//////////////// Search ////////////////
.header__search {
    @include rem('padding',
    0px 8px);
    @include breakpoint($bp-small) {
        @include rem('padding',
        0px 16px);
    }
    @include breakpoint($bp-medium) {
        width: 309px;
    }
    @include breakpoint($bp-large) {
        width: 342px;
    }
}

.header__advanced-search-link {
    display: none;
    @include breakpoint($bp-medium-1) {
        display: table-cell;
        text-decoration: none;
        color: #6d6d6d;
        @include rem('font-size',
        14px);
    }
}

//////////////// Menu ////////////////
.header__upgrade {
    @include rem('padding-right',
    15px);
    display: none;
    text-align: right;
    a {
        @include rem('font-size',
        15px);
        color: $green;
        text-decoration: none
    }
    @include breakpoint($bp-medium-2) {
        display: table-cell;
    }
}

.header__menu {
    @include rem('width',
    49px);
    border-left: 1px solid #ccc;
    @include breakpoint($bp-small) {
        @include rem('width',
        60px);
    }
    @include breakpoint($bp-medium) {
        border-left: none;
    }
}

.header__menu-guest,
.header__menu-guest .nav--header {
    @include breakpoint($bp-medium) {
        @include rem('width',
        216px);
    }
    @include breakpoint($bp-large) {
        @include rem('width',
        250px);
    }
}

.header__menu-user,
.header__menu-user .nav--header {
    @include breakpoint($bp-medium) {
        @include rem('width',
        135px);
    }
    @include breakpoint($bp-large) {
        @include rem('width',
        162px);
    }
}

.nav--header {
    @include breakpoint($bp-medium) {
        float: right;
    }
    &>ul {
        list-style-type: none;
        padding: 0;
        margin: 0;
        display: table;
        width: 100%;
    }
    &>ul>li {
        display: table-cell;
        vertical-align: middle;
        text-align: right;
    }
    .extra-nav {
        display: none;
        @include breakpoint($bp-medium) {
            display: table-cell;
        }
    }
    .header-dropdown {
        @include rem('top',
        45px);
        @include rem('right',
        -106px);
        @include rem('width',
        240px);
        position: absolute;
        display: block;
        z-index: 1;
        background: white;
        border: 1px solid #c8c8c8;
        box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.27);
        li {
            @include rem('padding',
            0px 12px);
            @include rem('height',
            35px);
            border-bottom: 1px solid #e9e9e9;
            position: relative;
            color: #323d4d;
            white-space: nowrap;
            text-align: left;
            @include rem('margin', 20px);
            a {
                @include rem('font-size',
                12px);
                @include rem('line-height',
                35px);
                color: $dark-text;
                text-decoration: none;
                display: block;
                @include breakpoint($bp-large) {
                    @include rem('font-size',
                    14px);
                }
            }
        }
        .profile-complete {
            @include rem('font-size',
            12px);
            @include rem('top',
            9px);
            @include rem('right',
            10px);
            float: right;
            font-weight: 500;
            color: #d59227;
            position: absolute;
            @include breakpoint($bp-large) {
                @include rem('font-size',
                14px);
            }
        }
        .account-detail-number {
            @include rem('font-size',
            12px);
            @include rem('top',
            8px);
            @include rem('right',
            10px);
            float: right;
            font-weight: 500;
            position: absolute;
            @include breakpoint($bp-large) {
                @include rem('font-size',
                14px);
            }
        }
        .log-out-list-item {
            text-align: center;
            background: #fafafa;
        }
        @include breakpoint($bp-large) {
            @include rem('top',
            52px);
            @include rem('right',
            -109px);
            @include rem('width',
            250px);
        }
    }
    .cart-dropdown {
        @include rem('top',
        38px);
        @include rem('right',
        0px);
        @include rem('width',
        274px);
        table {
            table-layout: fixed;
            width: 100%;
            td {
                @include rem('height',
                35px);
                @include rem('font-size',
                14px);
                text-align: left;
            }
            tr {
                border-bottom: 1px solid #e9e9e9;
            }
            .basket-item {
                @include rem('width',
                200px);
                @include rem('padding',
                0px 10px);
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
            .basket-price {
                @include rem('width',
                50px);
            }
            .basket-delete {
                @include rem('width',
                22px);
            }
        }
        .summary {
            @include cf;
            @include rem('padding',
            11px 10px);
            background: #fafafa;
            p {
                @include rem('padding-top',
                9px);
                float: left;
            }
            a {
                @include rem('padding',
                9px 20px);
                float: right;
            }
        }
        @include breakpoint($bp-small) {
            @include rem('top',
            44px);
        }
        @include breakpoint($bp-medium) {
            @include rem('right',
            -14px);
        }
        @include breakpoint($bp-large) {
            @include rem('top',
            52px);
            @include rem('right',
            -42px);
            @include rem('width',
            280px);
        }
        .dropdown-basket-item-delete-button {
            @include rem('height',
            16px);
        }
    }
    .header-dropdown:after,
    .header-dropdown:before {
        bottom: 100%;
        left: 50%;
        border: solid transparent;
        content: " ";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
    }
    .cart-dropdown:after,
    .cart-dropdown:before {
        left: 92%;
        @include breakpoint($bp-small) {
            left: 90%;
        }
        @include breakpoint($bp-large) {
            left: 80%;
        }
    }
    .header-dropdown:after {
        border-color: rgba(255, 255, 255, 0);
        border-bottom-color: #ffffff;
        border-width: 10px;
        margin-left: -10px;
    }
    .header-dropdown:before {
        border-color: rgba(202, 202, 202, 0);
        border-bottom-color: #cacaca;
        border-width: 11px;
        margin-left: -11px;
    }
    .nav-icon {
        display: block;
        text-align: center;
    }
    //////////////// Log in & Sign Up ////////////////
    .sign-up {
        @include breakpoint($bp-medium) {
            @include rem('padding-left',
            18px);
        }
        @include breakpoint($bp-large) {
            @include rem('padding-left',
            24px);
        }
    }
    .log-in-link {
        @include rem('font-size',
        14px);
        text-decoration: none;
        white-space: nowrap;
    }
    .sign-in-link {
        @include rem('font-size',
        14px);
        white-space: nowrap;
        @include breakpoint($bp-medium) {
            @include rem('padding',
            8px 31px);
        }
        @include breakpoint($bp-large) {
            @include rem('padding',
            8px 36px);
        }
    }
    //////////////// List elements ////////////////
    .cart,
    .account,
    .dashboard,
    .international {
        position: relative;
        @include breakpoint($bp-medium) {
            @include rem('padding-left',
            18px);
        }
        @include breakpoint($bp-large) {
            @include rem('padding-left',
            24px);
        }
    }
    //////////////// Icons ////////////////
    .dashboard-icon {
        background: url('/images/activity.svg') no-repeat center;
        margin: 0 auto;
        @include breakpoint($bp-small) {
            @include rem('height',
            24px);
            @include rem('width',
            24px);
        }
        @include breakpoint($bp-large) {
            @include rem('height',
            28px);
            @include rem('width',
            28px);
        }
    }
    .header-icon {
        color: rgb(151, 151, 151);
        font-size: 1.8rem;
    }
    .header-icon.light {
        color: white;
    }
    .international-icon {
        background: url('/images/globe.svg') no-repeat center;
        margin: 0 auto;
        background-size: cover;
        @include breakpoint($bp-small) {
            @include rem('height',
            24px);
            @include rem('width',
            24px);
        }
        @include breakpoint($bp-large) {
            @include rem('height',
            28px);
            @include rem('width',
            28px);
        }
    }
    .account-icon {
        background: url('/images/user.svg') no-repeat center;
        margin: 0 auto;
        @include breakpoint($bp-small) {
            @include rem('height',
            26px);
            @include rem('width',
            26px);
        }
        @include breakpoint($bp-large) {
            @include rem('height',
            30px);
            @include rem('width',
            30px);
        }
    }
    .cart-icon {
        background: url('/images/cart.svg') no-repeat center;
        margin: 0 auto;
        @include rem('height',
        21px);
        @include rem('width',
        21px);
        @include breakpoint($bp-small) {
            @include rem('height',
            24px);
            @include rem('width',
            24px);
        }
        @include breakpoint($bp-large) {
            @include rem('height',
            28px);
            @include rem('width',
            28px);
        }
    }
    .cart-number-of-items {
        @include rem('width',
        17px);
        @include rem('height',
        16px);
        @include rem('font-size',
        9px);
        @include rem('line-height',
        16px);
        @include rem('top',
        -6px);
        @include rem('right',
        5px);
        color: white;
        background: #ed4747;
        -webkit-border-radius: 8px;
        -moz-border-radius: 8px;
        border-radius: 8px;
        position: absolute;
        @include breakpoint($bp-small) {
            @include rem('right',
            9px);
        }
        @include breakpoint($bp-medium) {
            @include rem('right',
            -4px);
        }
        @include breakpoint($bp-large) {
            @include rem('width',
            22px);
            @include rem('height',
            21px);
            @include rem('font-size',
            12px);
            @include rem('line-height',
            21px);
            @include rem('top',
            -7px);
            @include rem('right',
            -7px);
            -webkit-border-radius: 12px;
            -moz-border-radius: 12px;
            border-radius: 12px;
        }
    }
}

.ie8 .nav--header .dashboard-icon {
    background: url('/images/navbar/activity.png') no-repeat center;
}

.ie8 .nav--header .account-icon {
    background: url('/images/navbar/user.png') no-repeat center;
}

.ie8 .nav--header .cart-icon {
    background: url('/images/navbar/cart.png') no-repeat center;
}