@import "../global";
.page-text {
    @include rem('padding-bottom', 30px);
    @include rem('font-size', 14px);
    @include rem('line-height', 22px);
    color: #494949;
    font-weight: 300;
    @include breakpoint($bp-medium) {
        @include rem('padding-bottom', 32px);
        @include rem('line-height', 23px);
    }
    @include breakpoint($bp-large) {
        @include rem('padding-bottom', 36px);
        @include rem('font-size', 16px);
        @include rem('line-height', 26px);
    }
}

.page-text.bold {
    font-weight: bold;
}

.page-text.underlined {
    text-decoration: underline;
}

.center {
    text-align: center;
}

ul.page-text {
    list-style-type: disc;
    @include rem('padding-left', 35px);
}

table.page-table * {
    border: 1px solid black;
    padding: 10px;
}

a {
    color: #3775d0;
}

.banner1 {
    background: url('/images/banner_1.png') no-repeat center center;
    background-size: cover;
}

.banner2 {
    background: url('/images/banner_2.png') no-repeat center center;
    background-size: cover;
}

//////////////// About ////////////////
.what-is-banner {
    background: url('/images/about/About-Banner.png') no-repeat center;
    background-size: cover;
    text-align: center;
    color: #ffffff;
    .bio-text-medium {
        display: none;
    }
    @include breakpoint($bp-medium) {
        .bio-text-medium {
            display: inline;
        }
    }
}

.mobile-remove {
    display: none;
    @include breakpoint($bp-medium) {
        display: block;
    }
}

.stats-banner {
    background: url('/images/about/Stats-Banner.png') no-repeat center;
    background-size: cover;
    .statistic {
        text-align: center;
        color: #ffffff;
        .statistic__icon {
            @include rem('height',
            59px);
            @include rem('margin',
            30px 0px 32px);
            @include breakpoint($bp-medium) {
                @include rem('height',
                82px);
                @include rem('margin',
                45px 0px 54px);
            }
            @include breakpoint($bp-large) {
                @include rem('height',
                94px);
                @include rem('margin',
                45px 0px 62px);
            }
        }
        .statistic__number {
            @include rem('font-size',
            23px);
            font-weight: 400;
            @include breakpoint($bp-medium) {
                @include rem('font-size',
                33px);
            }
            @include breakpoint($bp-large) {
                @include rem('font-size',
                38px);
            }
        }
        .statistic__label {
            @include rem('padding',
            10px 0px 42px);
            @include rem('font-size',
            14px);
            font-weight: 300;
            @include breakpoint($bp-medium) {
                @include rem('padding',
                8px 0px 44px);
                @include rem('font-size',
                19px);
            }
            @include breakpoint($bp-large) {
                @include rem('padding',
                10px 0px 44px);
                @include rem('font-size',
                22px);
            }
        }
    }
    .companies-stat {
        @include span(1 of 2);
        @include breakpoint($bp-medium) {
            @include span(1 of 3);
        }
    }
    .directors-stat {
        @include span(1 of 2 last);
        @include breakpoint($bp-medium) {
            @include span(1 of 3);
        }
    }
    .addresses-stat {
        .statistic__icon {
            margin-top: 0;
        }
        @include breakpoint($bp-medium) {
            @include span(1 of 3 last);
            .statistic__icon {
                @include rem('margin',
                45px 0px 54px);
            }
        }
        @include breakpoint($bp-large) {
            .statistic__icon {
                @include rem('margin',
                45px 0px 62px);
            }
        }
    }
}

.subscribe-form form {
    position: relative;
    @include rem('height',
    44px);
    @include rem('max-width',
    390px);
    @include breakpoint($bp-medium) {
        @include rem('height',
        46px);
    }
    @include breakpoint($bp-large) {
        @include rem('height',
        48px);
    }
    input[type="text"],
    input[type="email"] {
        @include rem('padding-left',
        14px);
        @include rem('padding-right',
        105px);
        @include rem('font-size',
        14px);
        font-weight: 300;
        width: 100%;
        height: 100%;
        display: block;
        border: 1px solid #d2d2d2;
        color: #323d4d;
        background: #f3f3f3;
        -webkit-border-radius: 4px;
        -moz-border-radius: 4px;
        border-radius: 4px;
        box-sizing: border-box;
        @include breakpoint($bp-medium) {
            @include rem('padding-left',
            16px);
        }
        @include breakpoint($bp-large) {
            @include rem('padding-left',
            18px);
        }
    }
    .subscribe-button {
        @include rem('padding',
        0px 15px);
        @include rem('font-size',
        13px);
        font-weight: 300;
        border: 0;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        background: #3775d0;
        color: #ffffff;
        -webkit-border-top-right-radius: 4px;
        -webkit-border-bottom-right-radius: 4px;
        -moz-border-radius-topright: 4px;
        -moz-border-radius-bottomright: 4px;
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
    }
}

//////////////// Information Pages ////////////////
//////// General ////////
.mobile-dropdown {
    @include rem('padding',
    20px 0px 30px);
}

.mobile-dropdown--tablet {
    @include span(1 of 4);
    @include rem('padding-top',
    90px);
    li {
        @include rem('padding-bottom',
        16px);
        a {
            @include rem('font-size',
            14px);
            text-decoration: none;
            @include breakpoint($bp-large) {
                @include rem('font-size',
                16px);
            }
        }
    }
    a.active {
        cursor: default;
        color: $dark-text;
    }
    @include breakpoint($bp-large) {
        @include span(1 of 5);
        @include rem('padding-top',
        138px);
    }
}

.policy-body {
    position: relative;
    h1 {
        @include rem('font-size',
        17px);
        @include rem('line-height',
        24px);
        font-weight: 500;
        @include breakpoint($bp-medium) {
            @include rem('padding-top',
            30px);
            @include rem('font-size',
            19px);
            font-weight: 400;
        }
        @include breakpoint($bp-large) {
            @include rem('padding-top',
            60px);
            @include rem('font-size',
            22px);
        }
    }
    h2 {
        @include rem('font-size',
        15px);
        @include rem('line-height',
        22px);
        @include rem('padding-bottom',
        30px);
        font-weight: 500;
        @include breakpoint($bp-medium) {
            @include rem('padding-bottom',
            32px);
            @include rem('font-size',
            17px);
            font-weight: 400;
        }
        @include breakpoint($bp-large) {
            @include rem('padding-bottom',
            36px);
            @include rem('font-size',
            20px);
        }
    }
    h3 {
        @include rem('font-size',
        15px);
        @include rem('line-height',
        22px);
        @include rem('padding-bottom',
        30px);
        font-weight: 500;
        @include breakpoint($bp-medium) {
            @include rem('padding-bottom',
            32px);
            @include rem('font-size',
            15px);
            font-weight: 400;
        }
        @include breakpoint($bp-large) {
            @include rem('padding-bottom',
            36px);
            @include rem('font-size',
            18px);
        }
    }
    .policy-body__policy {
        @include rem('padding',
        28px 0px 8px);
        @include breakpoint($bp-medium) {
            @include rem('padding',
            34px 0px 8px);
        }
        @include breakpoint($bp-large) {
            @include rem('padding',
            50px 0px 8px);
            @include rem('font-size',
            16px);
        }
    }
    .policy-body__policy>ol {
        @include rem('padding',
        0px 22px);
        @include rem('font-size',
        14px);
        @include rem('line-height',
        22px);
        font-weight: 500;
        margin: 0;
        ol {
            @include rem('padding',
            22px 0px 12px);
            font-weight: 300;
            li {
                @include rem('padding-bottom',
                30px);
                @include breakpoint($bp-medium) {
                    @include rem('padding-bottom',
                    16px);
                }
                @include breakpoint($bp-large) {
                    @include rem('padding-bottom',
                    24px);
                }
            }
            ol {
                @include rem('padding',
                0px 0px 0px 20px);
            }
            @include breakpoint($bp-medium) {
                @include rem('padding',
                15px 0px 34px);
            }
            @include breakpoint($bp-large) {
                @include rem('padding',
                25px 0px 36px);
            }
        }
    }
    .policy-body__policy:before {
        @include breakpoint($bp-medium) {
            content: " ";
            margin: 0 -60px;
            position: absolute;
            top: 85px;
            bottom: 50px;
            width: 1px;
            background: #d9d9d9;
        }
        @include breakpoint($bp-large) {
            margin: 0 -90px;
            top: 130px;
            bottom: 50px;
        }
    }
    @include breakpoint($bp-medium) {
        @include span(3 of 4 last);
    }
}

.message-container {
    .subscribe-form {
        display: inline-block;
        width: 390px;
    }
    .message-top {
        text-align: center;
        width: 100%;
        h1 {
            margin-bottom: 18px;
            font-size: 34px;
        }
        &--content {
            margin-top: 70px;
            margin-bottom: 54px;
        }
    }
    .message-description {
        font-size: 18px;
        margin-bottom: 40px;
    }
    .message-body {
        width: 718px;
        padding: 42px;
        margin: 0 auto;
        margin-bottom: 80px;
        text-align: left;
        &--filled-box {
            background-color: #F9F9F9;
            border: 1px solid #E6E6E6;
        }
        .page-text:last-child {
            padding-bottom: 0;
            ;
        }
    }
}

.cookie-table {
    @include rem('margin-bottom',
    30px);
}

.error-section {
    background: url('/images/error/errorpage_banner.png') no-repeat center center;
    background-size: cover;
    .container {
        @include rem('padding-top',
        66px);
        @include rem('padding-bottom',
        66px);
        text-align: center;
        @include breakpoint($bp-large) {
            @include rem('padding-top',
            79px);
            @include rem('padding-bottom',
            79px);
        }
    }
    h1 {
        @include rem('font-size',
        90px);
        font-weight: 500;
        color: white;
        @include breakpoint($bp-medium) {
            @include rem('font-size',
            122px);
        }
        @include breakpoint($bp-large) {
            @include rem('font-size',
            140px);
        }
    }
    h2 {
        @include rem('font-size',
        15px);
        @include rem('line-height',
        23px);
        font-weight: 300;
        color: white;
        @include breakpoint($bp-medium) {
            @include rem('padding-top',
            12px);
            @include rem('font-size',
            23px);
            @include rem('line-height',
            28px);
        }
        @include breakpoint($bp-large) {
            @include rem('font-size',
            26px);
            @include rem('line-height',
            32px);
        }
    }
    h1.generic {
        @include rem('font-size',
        40px);
        @include breakpoint($bp-medium) {
            @include rem('font-size',
            52px);
        }
        @include breakpoint($bp-large) {
            @include rem('font-size',
            60px);
        }
    }
    h2.generic {
        @include rem('padding-top',
        20px);
        @include rem('font-size',
        15px);
        @include rem('line-height',
        23px);
        @include breakpoint($bp-medium) {
            @include rem('font-size',
            23px);
            @include rem('line-height',
            28px);
        }
        @include breakpoint($bp-medium) {
            @include rem('font-size',
            26px);
            @include rem('line-height',
            32px);
        }
    }
    h2 a {
        color: white;
    }
}

.seen-this-section {
    @include rem('padding',
    40px 0px 50px);
    @include rem('max-width',
    615px);
    margin: 0 auto;
    .container {
        @include breakpoint($bp-medium) {
            @include rem('padding-top',
            38px);
            @include rem('padding-bottom',
            34px);
            border-top: 1px solid #d9d9d9;
            border-bottom: 1px solid #d9d9d9;
        }
        @include breakpoint($bp-large) {
            @include rem('padding-top',
            44px);
            @include rem('padding-bottom',
            40px);
        }
    }
    h3 {
        @include rem('font-size',
        17px);
        @include rem('line-height',
        24px);
        color: $dark-text;
        @include breakpoint($bp-medium) {
            @include rem('font-size',
            16px);
        }
        @include breakpoint($bp-large) {
            @include rem('font-size',
            18px);
        }
    }
    .seen-before-text {
        @include rem('padding',
        28px 0px 34px);
        @include rem('font-size',
        14px);
        @include rem('line-height',
        22px);
        color: #494949;
        @include breakpoint($bp-medium) {
            @include rem('padding',
            18px 0px);
            @include rem('font-size',
            14px);
            @include rem('line-height',
            21px);
        }
        @include breakpoint($bp-large) {
            @include rem('padding',
            20px 0px);
            @include rem('font-size',
            15px);
            @include rem('line-height',
            26px);
        }
    }
    @include breakpoint($bp-medium) {
        @include rem('padding',
        70px 0px);
    }
    @include breakpoint($bp-large) {
        @include rem('padding',
        80px 0px);
        @include rem('max-width',
        718px);
    }
}

.report-error-button {
    width: 100%;
    text-align: center;
    @include breakpoint($bp-medium) {
        @include rem('padding',
        9px 20px);
        @include rem('font-size',
        11px);
        width: initial;
    }
    @include breakpoint($bp-large) {
        @include rem('padding',
        9px 22px);
        @include rem('font-size',
        13px);
    }
}

//////////////// API ////////////////
.api-banner {
    img {
        @include rem('width',
        250px);
        @include rem('margin-bottom',
        20px);
        @include breakpoint($bp-medium) {
            @include rem('width',
            330px);
        }
        @include breakpoint($bp-large) {
            @include rem('width',
            385px);
        }
    }
    background: url('/images/api/api.png') no-repeat center center;
    background-size: cover;
}

.api-get-started-button {
    @include rem('margin-bottom',
    30px);
    @include rem('padding',
    12px 40px);
    @include breakpoint($bp-medium) {
        @include rem('margin-bottom',
        50px);
    }
    @include breakpoint($bp-large) {
        @include rem('margin-bottom',
        60px);
    }
}

.api-infographic {
    width: 100%;
}

ul.api-list {
    @include rem('padding-left',
    15px);
}

//////////////// Advertise ////////////////
.advertise-banner {
    background: url('/images/advertise.png') no-repeat center center;
    background-size: cover;
}

.landing-page-center {
    @include rem('max-width',
    560px);
    margin: 0 auto;
    @include breakpoint($bp-medium) {
        @include rem('max-width',
        646px);
    }
    @include breakpoint($bp-large) {
        @include rem('max-width',
        746px);
    }
}

.landing-page-form-header {
    @include rem('padding-bottom',
    28px);
    @include rem('font-size',
    17px);
    color: #323d4d;
    font-weight: 400;
    @include breakpoint($bp-medium) {
        @include rem('padding-bottom',
        30px);
        @include rem('font-size',
        19px);
    }
    @include breakpoint($bp-large) {
        @include rem('padding-bottom',
        35px);
        @include rem('font-size',
        22px);
    }
}

.landing-page-form-submit-button {
    @include rem('padding',
    12px 60px);
    @include breakpoint($bp-medium) {
        @include rem('margin-top',
        6px);
        float: right;
    }
    &.disabled {
        opacity: 0.5;
    }
}

.landing-page-form-errors {
    @include breakpoint($bp-medium) {
        float: right;
    }
    color: #d94141;
}

//////////////// Company ////////////////
.checkACompany-center {
    @include rem('margin-bottom',
    40px);
}

//////////////// FTSE100 ////////////////
.ftse100-section {
    @include rem('margin',
    40px 0px);
    @include breakpoint($bp-medium) {
        @include rem('margin',
        50px 0px);
    }
    @include breakpoint($bp-large) {
        @include rem('margin',
        60px 0px);
    }
}

.randomftses {
    @include cf;
}

.ftseRandom {
    border: 1px solid #d3d3d3;
    @include rem('margin-bottom',
    30px);
    @include breakpoint($bp-medium) {
        @include gallery(2 of 4);
        @include rem('margin-bottom',
        40px);
    }
    @include breakpoint($bp-large) {
        @include rem('margin-bottom',
        50px);
    }
    .ftseRandom__basic {
        @include rem('padding',
        22px);
        border-bottom: 1px solid #d3d3d3;
        a {
            @include rem('margin-bottom',
            10px);
            text-decoration: none;
            display: block;
            width: 100%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
    .ftseRandom__financial_container {
        @include cf;
        width: 100%;
        .ftseRandom__financial {
            @include rem('padding',
            25px);
            float: left;
            width: 50%;
            background: #FAFAFA;
            border-right: 1px solid #d3d3d3;
            border-bottom: 3px solid #d3d3d3;
            &:nth-child(2n) {
                border-right: 0;
                @include breakpoint($bp-large) {
                    border-right: 1px solid #d3d3d3;
                }
            }
            @include breakpoint($bp-large) {
                width: 25%;
                &:last-child {
                    border-right: 0;
                }
            }
            &.red {
                border-bottom: 3px solid #d94141;
            }
            &.green {
                border-bottom: 3px solid #00b800;
            }
        }
        .financial-label,
        .financial-value,
        .financial-percentage {
            display: block;
        }
        .financial-label {
            @include rem('font-size',
            11px);
            text-transform: uppercase;
        }
        .financial-value {
            @include rem('font-size',
            18px);
            @include rem('margin',
            11px 0px);
        }
        .financial-percentage {
            @include rem('font-size',
            14px);
            &.change-red {
                color: #d94141;
            }
            &.change-green {
                color: #00b800;
            }
            &.down:before {
                border-top: 8px solid #d94141;
            }
            &.up-red:before {
                border-bottom: 8px solid #d94141;
            }
            &.up:before {
                border-bottom: 8px solid #00b800;
            }
            &.down-green:before {
                border-top: 8px solid #00b800;
            }
            &:before {
                @include rem('margin-right',
                5px);
                content: "";
                display: block;
                width: 0;
                height: 0;
                border-left: 6.5px solid transparent;
                border-right: 6.5px solid transparent;
                float: left;
                margin-top: 4px;
            }
        }
    }
}

.contact-us-data-form-header {
    @include rem('margin-top',
    20px);
    @include breakpoint($bp-medium) {
        @include rem('margin-top',
        30px);
    }
    @include breakpoint($bp-large) {
        @include rem('margin-top',
        40px);
    }
}

.contact--us__data {
    h2 {
        text-align: center;
    }
    .search-box {
        @include rem('max-width',
        300px);
        margin: 0 auto;
        color: $dark-text;
        form {
            @include rem('height',
            34px);
            @include breakpoint($bp-medium) {
                @include rem('height',
                38px);
            }
            @include breakpoint($bp-large) {
                @include rem('height',
                42px);
            }
        }
    }
}

.removal-header {
    background: url('/images/removal/removals_banner.png') no-repeat center;
}

.removal-form-section {
    background: #F5F7F9;
}

.find-your-id-link {
    @include rem('font-size',
    14px);
    float: right;
    text-decoration: none;
}

.removal-request-form .form-detail input[type="text"] {
    background: white;
}

.removal-request-form .form-detail input[type="text"].selected-name {
    background: #F5F7F9;
}

.loading-selected {
    position: absolute;
    top: 8px;
    left: 40%;
}

.success-container {
    @include rem('padding-top',
    28px);
    @include rem('padding-bottom',
    50px);
    text-align: center;
    @include breakpoint($bp-medium) {
        @include rem('padding-top',
        34px);
        @include rem('padding-bottom',
        60px);
    }
    @include breakpoint($bp-large) {
        @include rem('padding-top',
        38px);
        @include rem('padding-bottom',
        80px);
    }
}

.success-image {
    @include rem('height',
    62px);
    @include breakpoint($bp-medium) {
        @include rem('height',
        78px);
    }
    @include breakpoint($bp-large) {
        @include rem('height',
        90px);
    }
}

.success-header {
    @include rem('font-size',
    19px);
    @include rem('padding',
    21px 0px 11px);
    font-weight: 500;
    color: #515a66;
    @include breakpoint($bp-medium) {
        @include rem('font-size',
        30px);
        @include rem('padding',
        26px 0px 15px);
    }
    @include breakpoint($bp-large) {
        @include rem('font-size',
        34px);
        @include rem('padding',
        32px 0px 18px);
    }
}

.success-subheader {
    @include rem('font-size',
    16px);
    font-weight: 300;
    color: #515a66;
    @include breakpoint($bp-medium) {
        @include rem('font-size',
        27px);
    }
    @include breakpoint($bp-large) {
        @include rem('font-size',
        30px);
    }
}

.removal-success-text-section {
    @include rem('padding',
    30px 15px);
    @include rem('max-width',
    700px);
    background: #FAFAFA;
    border: 1px solid #c9c9c9;
    margin: 20px auto 10px;
    text-align: left;
    h2,
    h3 {
        @include rem('padding-bottom',
        20px);
        font-weight: 400;
    }
    @include breakpoint($bp-medium) {
        @include rem('padding',
        30px);
    }
}

//////// Credit Score ////////
.sign-up-button {
    @include rem('margin-top',
    30px);
    @include rem('padding',
    12px 44px);
    @include breakpoint($bp-medium) {
        @include rem('margin-top',
        45px);
        @include rem('padding',
        16px 70px);
    }
}

//////// Data Cleaning ////////
.data-cleaning-form {
    padding-top: 0px;
}

//////// Testimonials ////////
.testimonials {
    @include cf;
    @include rem('padding',
    0px 0px 40px);
    background: #fff;
}

.case-studies {
    background: #f3f5f8;
}

.testimonials-container {
    margin: 0 auto;
}

.case-studies-header,
.testimonials-header {
    @include rem('font-size',
    22px);
    font-weight: 400;
    text-align: center;
}

.reviews-badge-section {
    text-align: center;
    padding: 40px 0px;
}

.reviews-banner {
    cursor: pointer;
}

//////// Debt Recovery ////////
.debt-banner {
    background: url('/images/debt_collection.png') no-repeat center center;
    background-size: cover;
    h1 {
        padding-bottom: 0.5em;
    }
    p {
        padding-top: 1.5em;
        padding-bottom: 1em;
    }
}

.get-quote-button {
    @extend .btn-style-1;
    @include rem('margin-top',
    20px);
    @include breakpoint($bp-medium) {
        @include rem('margin-top',
        40px);
    }
}

.get-quote-button-2 {
    @extend .btn-style-1;
    @include rem('margin-top',
    20px);
}

.quote-today {
    color: $primary;
}

.debt-steps {
    @include rem('padding',
    30px);
    @include rem('margin',
    30px 0px);
    background: #f5f7f9;
    border: 1px solid #e8e8e8;
    h3 {
        @include rem('font-size',
        18px);
        font-weight: 400;
        @include breakpoint($bp-medium) {
            @include rem('font-size',
            20px);
        }
        @include breakpoint($bp-large) {
            @include rem('font-size',
            22px);
        }
    }
    ol {
        @include rem('padding',
        20px);
        padding-right: 0px;
        margin: 0px;
        font-weight: 300;
        li {
            margin-bottom: 8px;
        }
    }
    p {
        font-weight: 500;
    }
    @include breakpoint($bp-medium) {
        @include rem('margin',
        55px 0px);
    }
}

.debt-did-you-know-list {
    @include rem('margin',
    0px 0px 20px 20px);
    list-style-type: disc;
    font-weight: 300;
}

.debt-info-source {
    @include rem('padding-top',
    8px);
    border-top: 1px solid #e8e8e8;
    font-weight: 300;
}

.debt-sidebar {
    @include rem('margin-top',
    30px);
    h3 {
        @include rem('font-size',
        16px);
        @include rem('margin-bottom',
        5px);
        font-weight: 400;
    }
    p {
        @include rem('margin-bottom',
        20px);
        @include rem('line-height',
        26px);
        font-weight: 300;
    }
    @include breakpoint($bp-medium) {
        margin-top: 0;
    }
}

.hidden {
    display: none;
}