@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .Company-header__company-info {
    display: -ms-flexbox;
  }

  .visibility--tablet {
    display: block !important;
  }

  .Company-header__print img {
    height: 20px;
  }
}