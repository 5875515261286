%Upsell-message {
    @include cf;
    @include rem('padding', 15px 10px);
    border: 1px solid #d9d9d9;
    text-align: center;

    @include breakpoint($bp-medium) {
        @include rem('padding', 25px 15px 15px);
    }
}

.Credit-report-upsell__booklet-container {
    @include breakpoint($bp-medium) {
        @include rem('margin-top', 20px);
        @include span(2 of 11);
    }
}

.Credit-report-upsell__booklet {
    @include rem('width', 125px);
    @include rem('margin-bottom', 10px);

    @include breakpoint($bp-medium) {
        margin-bottom: 0;
    }
}

.Credit-report-upsell__lock {
    @include rem('width', 12px);
    @include rem('margin-right', 2px);
}

.Credit-report-upsell__text {
    h2 {
        @include rem('font-size', 16px);
        @include rem('margin-bottom', 10px);
        font-weight: 400;

        @include breakpoint($bp-medium) {
            @include rem('font-size', 18px);
        }

        @include breakpoint($bp-large) {
            @include rem('font-size', 20px);
        }
    }

    p {
        @include rem('font-size', 14px);
        @include rem('line-height', 24px);
        @include rem('max-width', 420px);
        font-weight: 300;
        margin: 0 auto;

        @include breakpoint($bp-medium) {
            @include rem('font-size', 16px);
            @include rem('line-height', 26px);
            margin: 0;
            max-width: none;
        }
    }

    @include breakpoint($bp-medium) {
        text-align: left;
        @include span(6 of 11);
    }
}

.Credit-report-upsell__find-more-container {
    @include breakpoint($bp-medium) {
        @include span(3 of 11 last);
    }
}

.Credit-report-upsell__find-more {
    @extend .btn-style-1;
    @include rem('margin-top', 10px);
    @include rem('font-size', 14px);

    background: #fff;
    border: 1px solid #bbc3ce;
    color: #4a5461;

    -webkit-box-shadow: 3px 3px 10px 0px rgba(0,0,0,0.3);
    -moz-box-shadow: 3px 3px 10px 0px rgba(0,0,0,0.3);
    box-shadow: 3px 3px 10px 0px rgba(0,0,0,0.3);

    @include breakpoint($bp-medium) {
        @include rem('margin-top', 35px);
        @include rem('font-size', 16px);
        @include rem('padding', 14px 35px);
    }
}