.Plans-container {
    display: inline-block;
    text-align: center;
    width: 100%;
}

.Plan {
    @include rem('max-width', 300px);
    margin: 30px auto 0px;
    color: #525965;
    background: #fff;
    position: relative;

    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;

    @include breakpoint(730px) {
        @include rem('width', 310px);
        @include rem('margin', 30px 15px 0px);
        max-width: none;
        display: inline-block;
    }

    @include breakpoint($bp-medium-1) {
        @include rem('margin', 30px 0px 0px);
        @include rem('height', 400px);
        width: 25%;
        float: left;
        border-radius: 0px;
        border-right: 1px solid #cdd4da;
    }

    &--free {
        @include breakpoint($bp-medium-1) {
            margin-left: -100%;

            -webkit-border-top-left-radius: 4px;
            -webkit-border-bottom-left-radius: 4px;
            -moz-border-radius-topleft: 4px;
            -moz-border-radius-bottomleft: 4px;
            border-top-left-radius: 4px;
            border-bottom-left-radius: 4px;
        }
    }

    &--lite {
        @include breakpoint($bp-medium-1) {
            margin-left: 25%;
        }
    }

    &--enterprise {
        @include breakpoint($bp-medium-1) {
            -webkit-border-top-right-radius: 4px;
            -webkit-border-bottom-right-radius: 4px;
            -moz-border-radius-topright: 4px;
            -moz-border-radius-bottomright: 4px;
            border-top-right-radius: 4px;
            border-bottom-right-radius: 4px;
        }
    }
}

.Plan__header {
    @include rem('height', 90px);
    background: #f3f7fc;
    position: relative;

    -webkit-border-top-left-radius: 4px;
    -webkit-border-top-right-radius: 4px;
    -moz-border-radius-topleft: 4px;
    -moz-border-radius-topright: 4px;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
}

.Plan__name-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.Plan__name {
    @include rem('font-size', 14px);
    font-weight: 500;
}

.Plan__price {
    @include rem('font-size', 20px);

    span {
        @include rem('font-size', 12px);
    }
}

.Plan__info {
    @include rem('padding', 25px 20px 50px);
}

.Plan__docs, .Plan__accounts, .Plan__debt-recovery {
    @include rem('font-size', 12px);
    @include rem('line-height', 20px);
    font-weight: 300;
    display: block;
}

.Plan__reports {
    @include rem('font-size', 70px);
    @include rem('margin-top', 8px);
    font-weight: 300;
}

.Plan__reports-label {
    @include rem('font-size', 16px);
    @include rem('margin-top', -8px);
    font-weight: 300;
}

.Plan__buy-button {
    @extend .btn-style-1;
    @extend .btn-style-green;
    @include rem('font-size', 15px);
    @include rem('padding', 10px 43px);
    @include rem('margin-top', 30px);

    &--disabled {
        @include rem('padding', 10px 35px);
        color: #6f7581;
        background: rgba(255,255,255, 0);
        border: 1px solid #d4d9de;
        cursor: default;
    }
}

.Plan__billed-annually {
    @include rem('font-size', 10px);
    @include rem('margin-top', 10px);
    display: block;
}

.Plan__users-pill {
    @include rem('width', 140px);
    @include rem('height', 46px);
    @include rem('font-size', 13px);
    @include rem('padding', 14px);
    font-weight: 700;
    background: #f3f7fc;
    position: absolute;
    top: -22px;
    left: 0;
    right: 0;
    z-index: 2;
    border-radius: 23px;
    margin: 0 auto;

    img {
        @include rem('width', 10px);
        @include rem('margin-right', 5px);
        display: inline;
    }

    p {
        display: inline;
    }
}

.Plans-container--discount {

    .Plan {
        @include rem('height', 430px);

        @include breakpoint($bp-medium-1) {
            width: 33%;
        }

        &--free {
            display: none;
        }

        &--lite {
            @include breakpoint($bp-medium-1) {
                margin-left: 0;
            }
        }
    }

    .Plan__header {
        @include rem('height', 130px);
    }

    .Plan__price--strike {
        position: relative;

        &:after {
            content:"";    /* required property */
            position: absolute;
            bottom: 0;
            left: 0;
            border-top: 2px solid red;
            height: 45%;
            width: 100%;
            transform: rotateZ(-4deg);
        }
    }

    .Plan__price:last-child {
        @include rem('margin-top', 10px);
        @include rem('padding-top', 7px);
        border-top: 1px solid #d2d2d2;
    }
}