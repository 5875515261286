@mixin cf {
    &:after {
        content: "";
        display: block;
        clear: both;
    }
}

@mixin cf-override {
    &:after {
        display: none;
        clear: none;
    }
}

@mixin header-offset {
    &:before {
        content: " ";
        display: block;
        padding-top: 50px;

        @include breakpoint($bp-small) {
            padding-top: 60px;
        }

        @include breakpoint($bp-large) {
            padding-top: 70px;
        }

    }
}

@mixin hide {
    display: none !important;
}

@mixin rem($property, $px-values) {

    $baseline-rem: $base-font-size / 1rem;
    #{$property}: $px-values;
    @if type-of($px-values) == "number" {
        #{$property}: $px-values / $baseline-rem; }
    @else {
        $rem-values: unquote("");
        @each $value in $px-values {
            @if $value == '0px' {
                $rem-values: append($rem-values, $value);
            } @else {
                $rem-values: append($rem-values, $value / $baseline-rem);
            }
        }
        #{$property}: $rem-values;
    }
}



