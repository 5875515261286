///////// General ////////

.banner .container{
    @include rem('padding-top', 40px);
    @include rem('padding-bottom', 40px);
}

.banner__header {
    @include rem('font-size', 27px);
    @include rem('line-height', 32px);

    @include breakpoint($bp-medium) {
        @include rem('font-size', 39px);
        @include rem('line-height', 47px);
    }

    @include breakpoint($bp-large) {
        @include rem('font-size', 45px);
        @include rem('line-height', 54px);
    }
}

.banner__text {

    @include rem('font-size', 17px);
    @include rem('line-height', 23px);
    @include rem('margin-top', 24px);

    @include breakpoint($bp-medium) {
        @include rem('font-size', 21px);
        @include rem('line-height', 26px);
        @include rem('margin-top', 26px);
    }

    @include breakpoint($bp-large) {
        @include rem('font-size', 24px);
        @include rem('line-height', 30px);
        @include rem('margin-top', 22px);
    }
}

//////// Main Landing Pages ////////

.marketing-section {
    color: white;
    text-align: center;

    h1 {
        @include rem('font-size', 32px);
        font-weight: 500;

        @include breakpoint($bp-medium) {
            @include rem('font-size', 45px);
        }

        @include breakpoint($bp-large) {
            @include rem('font-size', 52px);
        }
    }

    h1.main {
        @include rem('font-size', 24px);
        @include rem('line-height', 32px);
        font-weight: 500;

        @include breakpoint($bp-medium) {
            @include rem('font-size', 33px);
        }

        @include breakpoint($bp-large) {
            @include rem('font-size', 38px);
        }
    }

    h2 {
        @include rem('padding', 20px 0px 27px);
        @include rem('font-size', 20px);
        @include rem('line-height', 28px);
        font-weight: 300;
        opacity: 0.7;

        @include breakpoint($bp-medium) {
            @include rem('padding', 28px 0px 32px);
            @include rem('font-size', 26px);
        }

        @include breakpoint($bp-large) {
            @include rem('padding', 32px 0px 36px);
            @include rem('font-size', 30px);
        }
    }

    h2.main {
        @include rem('font-size', 16px);
        @include rem('line-height', 24px);

        @include breakpoint($bp-medium) {
            @include rem('padding', 20px 0px 44px);
            @include rem('font-size', 22px);
        }

        @include breakpoint($bp-large) {
            @include rem('font-size', 26px);
        }
    }

    h3 {
        @include rem('padding-bottom', 27px);
        @include rem('font-size', 18px);
        @include rem('line-height', 26px);
        font-weight: 300;
        opacity: 0.7;

        @include breakpoint($bp-medium) {
            @include rem('padding-bottom', 32px);
            @include rem('font-size', 24px);
        }

        @include breakpoint($bp-large) {
            @include rem('padding-bottom', 36px);
            @include rem('font-size', 28px);
        }
    }

    .resubscribe-button {

        background: $green;
        color: white;

        @include rem('padding', 11px 35px);
        @include rem('font-size', 13px);

        @include breakpoint($bp-medium) {
            @include rem('font-size', 16px);
            @include rem('padding', 14px 35px);
        }

        @include breakpoint($bp-large) {
            @include rem('font-size', 18px);
            @include rem('padding', 15px 40px);
        }
    }

    .sign-up-section {
        color: $dark-text;

        @include rem('margin-bottom', 85px);

        @include breakpoint($bp-medium) {
            @include rem('margin-bottom', 100px);
        }

        @include breakpoint($bp-large) {
            @include rem('margin-bottom', 110px);
        }
    }

    .coupon-section {
        @include rem('margin-top', 55px);
        @include rem('margin-bottom', 45px);
        @include rem('padding', 15px 20px);
        @include rem('font-size', 14px);
        @include rem('line-height', 22px);
        @include rem('max-width', 475px);
        border: 3px dashed rgba(255,255,255,0.5);;
        background: rgba(255,255,255,0.1);
        font-weight: 300;
        margin-left: auto;
        margin-right: auto;

        @include breakpoint($bp-medium) {
            @include rem('margin-top', 96px);
            @include rem('margin-bottom', 66px);
            @include rem('padding', 18px 22px);
            @include rem('font-size', 16px);
        }

        @include breakpoint($bp-large) {
            @include rem('margin-top', 110px);
            @include rem('margin-bottom', 74px);
            @include rem('padding', 20px 25px);
            @include rem('font-size', 18px);
            @include rem('max-width', 542px);
        }

        span {
            color: #01d901;
            text-transform: uppercase;
            text-decoration: underline;
            font-weight: 500;
        }
    }

    .coupon-section--come-back {
        @include rem('margin-top', 10px);
        @include rem('margin-bottom', 10px);
    }

    .marketing-section__text {
        @include rem('margin-top', 55px);
        @include rem('margin-bottom', 45px);
        @include rem('font-size', 14px);
        @include rem('line-height', 22px);
        @include rem('max-width', 805px);
        font-weight: 300;
        margin-left: auto;
        margin-right: auto;
        opacity: 0.7;

        @include breakpoint($bp-medium) {
            @include rem('margin-top', 96px);
            @include rem('margin-bottom', 66px);
            @include rem('font-size', 16px);
            @include rem('line-height', 26px);
        }

        @include breakpoint($bp-large) {
            @include rem('margin-top', 110px);
            @include rem('margin-bottom', 74px);
            @include rem('font-size', 18px);
            @include rem('line-height', 30px);
        }
    }

    .arrow-down {
        @include rem('height', 12px);
        margin: 0 auto;
        display: block;

        @include breakpoint($bp-medium) {
            @include rem('height', 18px);
        }

        @include breakpoint($bp-large) {
            @include rem('height', 21px);
        }
    }

    a {
        color: white;
    }
}


.content--floating-header .marketing-1 {
    @include rem('padding', 140px 0px 23px);

    @include breakpoint($bp-large) {
        @include rem('padding', 160px 0px 32px);
    }
}

.marketing-1 {
    @include rem('padding', 46px 0px 23px);
    background: url('/images/subscription/marketing_banner.png') no-repeat center center;
    background-size: cover;

    @include breakpoint($bp-medium) {
        @include rem('padding', 75px 0px 34px);
    }

    @include breakpoint($bp-large) {
        @include rem('padding', 88px 0px 38px);
    }
}

.marketing-2 {
    @include rem('padding', 45px 0px);
    background: url('/images/subscription/marketing_banner.png') no-repeat center center;
    background-size: cover;

    .sign-up-section {
        @include rem('margin-bottom', 45px);

        @include breakpoint($bp-medium) {
            @include rem('margin-bottom', 60px);
        }

        @include breakpoint($bp-large) {
            @include rem('margin-bottom', 70px);
        }
    }

    .coupon-section {
        margin-bottom: 0px;
        @include rem('margin-top', 30px);

        @include breakpoint($bp-medium) {
            @include rem('margin-top', 44px);
        }

        @include breakpoint($bp-large) {
            @include rem('margin-top', 50px);
        }
    }

    @include breakpoint($bp-medium) {
        @include rem('padding', 70px 0px 84px);
    }

    @include breakpoint($bp-large) {
        @include rem('padding', 80px 0px 98px);
    }
}

.trust-section {
    @include rem('padding', 24px 0px 25px);
    @include rem('font-size', 15px);
    @include rem('line-height', 24px);
    background: #4b5e78;
    color: white;
    text-align: center;

    @include breakpoint($bp-medium-1) {
        @include rem('padding', 36px 0px 28px);
        @include rem('font-size', 19px);
    }

    @include breakpoint($bp-large) {
        @include rem('padding', 38px 0px 30px);
        @include rem('font-size', 22px);
    }

    &--grey {
        background: #f3f5f8;
        padding-bottom: 0px;
        color: #69707a;

        p {
            opacity: 0.8;
        }
    }

    &--white {
        background: white;
        color: $dark-text;
        opacity: 0.8;
    }
}

.business-logos-section {
    @include cf;
    @include rem('padding', 28px 10px);
    background: #f3f5f8;
    text-align: center;

    img {
        @include rem('margin', 12px 15px);

        @include breakpoint($bp-large) {
            @include rem('margin', 12px 30px);
        }
    }

    @include breakpoint($bp-medium-1) {
        @include rem('padding', 12px 10px 36px);
    }

    @include breakpoint($bp-large) {
        @include rem('padding', 20px 0px 38px);
    }

    &--white {
        background: white;;
    }
}

.banner-main {
    @include rem('padding', 40px 0px 43px);
    @include cf;

    @include breakpoint($bp-medium) {
        @include rem('padding', 80px 0px);
    }

    @include breakpoint($bp-large) {
        @include rem('padding', 90px 0px);
    }

    &--grey {
        background: #f3f5f8;
    }

    &--no-bp{
        @include breakpoint($bp-medium) {
            padding-bottom: 0px;
        }
    }
}

.banner-main__details {
    @include rem('padding-top', 33px);
    text-align: center;

    @include breakpoint($bp-medium) {
        @include rem('padding-top', 15px);
    }

    @include breakpoint($bp-large) {
        @include rem('padding-top', 74px);
    }
}

.claim-details {
    @include breakpoint($bp-medium) {
        @include rem('padding-top', 70px);
    }

    @include breakpoint($bp-large) {
        @include rem('padding-top', 85px);
    }
}

.details-left {
    @include breakpoint($bp-medium) {
        width: 48%;
        float: left;
        text-align: left;
    }
}

.details-right {
    @include breakpoint($bp-medium) {
        width: 52%;
        float: right;
        text-align: left;
    }
}

.banner-main-header {
    @include rem('font-size', 28px);
    font-weight: 500;
    color: #2c394b;

    @include breakpoint($bp-medium-2) {
        @include rem('font-size', 38px);
    }

    @include breakpoint($bp-large) {
        @include rem('font-size', 44px);
    }
}

.banner-main-text {
    @include rem('font-size', 15px);
    @include rem('line-height', 26px);
    @include rem('margin-top', 40px);
    color: #6a717b;

    @include breakpoint($bp-medium) {
        @include rem('margin-top', 30px);
        font-weight: 300;
    }

    @include breakpoint($bp-medium-2) {
        @include rem('font-size', 17px);
    }

    @include breakpoint($bp-large) {
        @include rem('line-height', 32px);
        @include rem('margin-top', 40px);
    }
}

.list {
    list-style-type: disc;
    list-style-position: inside;
}

.list--left {
    @include breakpoint($bp-medium) {
        width: 48%;
        float: left;
    }
}

.list--right {
    @include breakpoint($bp-medium) {
        width: 48%;
        float: right;
    }
}

.more-info-button {
    @include rem('margin-top', 40px);
}

.image-container--macbook {
    @include rem('height', 180px);
    background: url('/images/home/macbook.png') no-repeat center;
    background-size: 296px 180px;

    @include breakpoint($bp-medium) {
        @include rem('height', 310px);
        background-size: 510px 310px;
        background-position: right center;
    }

    @include breakpoint($bp-large) {
        @include rem('height', 470px);
        background-size: 773px 470px;
    }
}

.image-container--tablet {
    @include rem('height', 306px);
    background: url('/images/home/director_ipad.png') no-repeat center;
    background-size: 196px 306px;

    @include breakpoint($bp-medium) {
        @include rem('height', 410px);
        background-size: 508px 794px;
        background-position: left 0px;
    }

    @include breakpoint($bp-large) {
        @include rem('height', 570px);
        background-size: 585px 916px;
    }
}

.image-container--phone {
    @include rem('height', 284px);
    background: url('/images/home/claim_iphone.png') no-repeat center;
    background-size: 123px 284px;

    @include breakpoint($bp-medium) {
        @include rem('height', 410px);
        background-size: 360px 842px;
        background-position: right top;
    }

    @include breakpoint($bp-large) {
        @include rem('height', 520px);
        background-size: 412px 974px;
    }
}

.image-container--phone.image-container--left {
    @include breakpoint($bp-medium) {
        float: left;
        width: 42%;
    }
}

.image-container--left {
    @include breakpoint($bp-medium) {
        float: left;
        width: 45%;
    }
}

.image-container--right {
    @include breakpoint($bp-medium) {
        float: right;
        width: 45%;
    }
}

//////// Static ////////

.title-banner {
    @include rem('padding', 52px 0px 58px);
    background: $primary;
    background-size: cover;
    text-align: center;
    color: #ffffff;

    h1 {
        @include rem('font-size', 22px);
        @include rem('padding-bottom', 23px);
        font-weight: 300;

        @include breakpoint($bp-medium) {
            @include rem('font-size', 28px);
            @include rem('padding-bottom', 40px);
        }

        @include breakpoint($bp-large) {
            @include rem('font-size', 32px);
            @include rem('padding-bottom', 46px);
        }
    }

    p {
        @include rem('font-size', 15px);
        @include rem('line-height', 22px);
        @include rem('max-width', 560px);
        margin: 0 auto;
        font-weight: 100;

        @include breakpoint($bp-medium) {
            @include rem('font-size', 17px);
            @include rem('line-height', 28px);
            @include rem('max-width', 646px);
        }

        @include breakpoint($bp-large) {
            @include rem('font-size', 20px);
            @include rem('line-height', 32px);
            @include rem('max-width', 746px);
        }
    }

    a {
        color: #ffffff;
    }

    @include breakpoint($bp-medium) {
        @include rem('padding', 90px 0px 100px);
    }

    @include breakpoint($bp-large) {
        @include rem('padding', 106px 0px 116px);
    }
}

.info-banner {
    @include rem('padding', 40px 0px 10px);

    @include breakpoint($bp-medium) {
        @include rem('padding', 78px 0px 50px);
    }

    @include breakpoint($bp-large) {
        @include rem('padding', 90px 0px 66px);
    }


    h2 {
        @include rem('padding-bottom', 28px);
        @include rem('font-size', 17px);
        color: #323d4d;
        font-weight: 400;

        @include breakpoint($bp-medium) {
            @include rem('padding-bottom', 30px);
            @include rem('font-size', 19px);
        }

        @include breakpoint($bp-large) {
            @include rem('padding-bottom', 35px);
            @include rem('font-size', 22px);
        }
    }

    .page-text {
        @include rem('padding-bottom', 30px);
        @include rem('font-size', 14px);
        @include rem('line-height', 22px);
        color: #494949;
        font-weight: 300;

        @include breakpoint($bp-medium) {
            @include rem('padding-bottom', 32px);
            @include rem('line-height', 23px);
        }

        @include breakpoint($bp-large) {
            @include rem('padding-bottom', 36px);
            @include rem('font-size', 16px);
            @include rem('line-height', 26px);
        }
    }

    .two-col__left {
        @include breakpoint($bp-medium) {
            @include span(2 of 3);
        }
    }

    .two-col__right {
        @include breakpoint($bp-medium) {
            @include span(1 of 3 last);
        }
    }
}

.two-col__left-50 {
    @include breakpoint($bp-medium) {
        @include span(2 of 4);
    }
}

.two-col__right-50 {
    @include breakpoint($bp-medium) {
        @include span(2 of 4 last);
    }
}
.recent-searches-banner__text {

    margin: 0 auto;
    width: 375px;

    div {
        float: left;
        text-align: center;
        cursor: pointer;
        font-size: 24px !important;
        width: 125px;
        margin-top: 24px;

        span {
            font-size: 20px !important;
        }
    }

    @include breakpoint($bp-medium) {
        display: none;
    }
}

.recent-searches-banner__charts {

    display: none;

    @include cf;

    @include breakpoint($bp-medium) {
        display: block;
        @include rem('margin-top', 95px);
        @include rem('margin-bottom', 77px);
    }

    @include breakpoint($bp-large) {
        @include rem('margin-top', 88px);
        @include rem('margin-bottom', 88px);
    }

    .search-chart {

        @include rem('margin-top', 30px);
        display: block;
        text-align: center;
        cursor: pointer;

        @include breakpoint($bp-medium) {
            margin-top: 0;
            @include rem('margin-right', 52px);
            display: inline;

            @include breakpoint($bp-large) {
                @include rem('margin-right', 62px);
            }

            &:last-child {
                margin-right: 0;
            }
        }
    }

    .search-chart__text {
        color: #323d4d;
        font-size: 65px !important;
        font-weight: 100;
        top: 40% !important;
        line-height: 35px !important;
        height: inherit !important;

        @include breakpoint($bp-large) {
            font-size: 80px !important;
        }

        .label {
            font-size: 21px !important;
            font-weight: 300;

            @include breakpoint($bp-large) {
                font-size: 24px !important;
            }
        }
    }
}

.free-trial-banner {
    @include rem('padding', 50px 0px 58px);
    background: url('/images/signup_banner.png') no-repeat center center;
    background-size: cover;
    text-align: center;

    p {
        @include rem('font-size', 20px);
        @include rem('margin-bottom', 22px);
        color: #fff;

        @include breakpoint($bp-medium) {
            @include rem('font-size', 26px);
            @include rem('margin-bottom', 32px);
        }

        @include breakpoint($bp-large) {
            @include rem('font-size', 30px);
            @include rem('margin-bottom', 36px);
        }
    }

    @include breakpoint($bp-medium) {
        @include rem('padding', 82px 0px 98px);
    }

    @include breakpoint($bp-large) {
        @include rem('padding', 96px 0px 110px);
    }
}

.free-trial-banner__button {
    @extend .btn-style-1;
    @extend .btn-style-green;

    @include rem('font-size', 13px);
    @include rem('padding', 11px 32px);

    @include breakpoint($bp-medium) {
        @include rem('font-size', 15px);
        @include rem('padding', 15px 47px);
    }
}

.deep-blue-section {
    @include rem('padding', 24px 10px 26px);
    @include rem('font-size', 16px);
    @include rem('line-height', 26px);
    background: #2c394b;
    color: #a0a0a0;
    text-align: center;

    @include breakpoint($bp-medium) {
        @include rem('padding', 42px 10px 44px);
        @include rem('font-size', 23px);
        @include rem('line-height', 32px);
    }

    @include breakpoint($bp-large) {
        @include rem('padding', 50px 10px 52px);
        @include rem('font-size', 26px);
        @include rem('line-height', 35px);
    }

    span {
        color: white;
    }

    .nowrap {
        white-space: nowrap;
    }
}
