.popup {
    @include cf;
    position: relative;
    background: #FFF;
    padding: 44px;
    width: auto;
    max-width: 540px;
    margin: 20px auto;
}

.popup-header {
    @include rem('font-size', 22px);
    @include rem('margin-bottom', 15px);
    font-weight: 400;
}

.popup-subheader {
    @include rem('font-size', 18px);
    @include rem('margin', 15px 0px);
    font-weight: 400;
}

.popup-text {
    @include rem('font-size', 15px);
    @include rem('line-height', 18px);
    @include rem('margin-bottom', 15px);
    font-weight: 400;
    color: #A7ABAF;
}

.login-popup {
    @extend .popup;
    max-width: 460px;
    padding: 0px;
    background: none;

    @include breakpoint($bp-large) {
        max-width: 524px;
    }
}

.upload-popup {
    @extend .popup;
}

///////// Find Your ID Popup ////////

.find-your-id-left {

    img {
        display: block;
        margin: 0 auto;
    }

    @include breakpoint($bp-medium) {
        width: 45%;
        float: left;
    }
}

.find-your-id-right {

    img {
        display: block;
        margin: 0 auto;
    }

    @include breakpoint($bp-medium) {
        width: 45%;
        float: right;
    }
}

//////////////// Claim popups ////////////////

.claim-popup {
    @extend .popup;
    padding: 20px;

    @include breakpoint($bp-medium) {
        padding: 44px;
    }

    @include breakpoint($bp-medium-1) {
        max-width: 775px;
    }
}


h2.claim-header {
    @include rem('padding-bottom', 28px);
    @include rem('font-size', 17px);
    color: #323d4d;
    font-weight: 400;

    @include breakpoint($bp-medium) {
        @include rem('padding-bottom', 30px);
        @include rem('font-size', 19px);
    }

    @include breakpoint($bp-large) {
        @include rem('padding-bottom', 35px);
        @include rem('font-size', 22px);
    }
}

.claim-director-table-pagination {
    @include rem('margin-top', 20px);
}

.claim-page-text {
    @include rem('padding-bottom', 30px);
    @include rem('font-size', 14px);
    @include rem('line-height', 22px);
    color: #494949;
    font-weight: 300;

    @include breakpoint($bp-medium) {
        @include rem('padding-bottom', 32px);
        @include rem('line-height', 23px);
    }

    @include breakpoint($bp-large) {
        @include rem('padding-bottom', 36px);
        @include rem('font-size', 16px);
        @include rem('line-height', 26px);
    }
}

.claim-contact-form {
    @include cf;
}

.claim-contact-description {
    text-align: center;
    max-width: 90%;
    margin: 0 auto;
}

.claim-contact-form-input-container {
    @include cf;
}

.claim-director-company {
    @include rem('font-size', 16px);
    @include rem('line-height', 26px);
    @include rem('margin-bottom', 10px);
    color: #acafb4;

    @include breakpoint($bp-large) {
        @include rem('font-size', 18px);
        @include rem('line-height', 28px);
    }
}

.claim-contact-submit-button {
    @include rem('padding', 12px 60px);
    @include rem('max-width', 260px);
    text-align: center;
    display: block;
    margin: 0 auto;
}

.why-claim-link {
    text-align: center;
    display: block;
    color: $primary;
}

//////////////// Upsell popups ////////////////

%upsell-popup {
    @extend .popup;
    text-align: center;
    padding: 0px;
}

%upsell-popup__top-section {
    @include rem('padding', 35px 20px);
    color: #000000;

    @include breakpoint($bp-medium) {
        @include rem('padding', 35px 30px);
    }

    h2 {
        @include rem('margin', 25px 0px 20px);
        @include rem('font-size', 18px);
        font-weight: 500;
        padding: 0;

        @include breakpoint($bp-medium) {
            @include rem('font-size', 20px);
        }

        @include breakpoint($bp-large) {
            @include rem('font-size', 22px);
        }
    }

    p {
        @include rem('font-size', 16px);
        @include rem('margin-bottom', 10px);
        @include rem('line-height', 20px);

        @include breakpoint($bp-medium) {
            @include rem('font-size', 18px);
            width: 80%;
            margin: 0 auto;
        }
    }
}

%upsell-popup__button {
    @include rem('padding', 12px 28px);
    @include rem('margin', 30px 10px);
    border-radius: 4px;
    display: inline-block;

    @include breakpoint($bp-large) {
        @include rem('padding', 14px 36px);
    }
}

%upsell-popup__top-section--plan-table {
    @include rem('padding', 35px 20px);

    @include breakpoint($bp-medium) {
        @include rem('padding', 35px 30px);
    }

    h2 {
        @include rem('margin', 0px 0px 0px 7px);
        padding: 0;
        width: 80%;
        text-align: left;

        @include breakpoint($bp-medium) {
            text-align: center;
            width: 100%;
        }
    }

    .upsell-subscriptions {
        @include rem('padding', 26px 0px 34px);

        @include breakpoint($bp-large) {
            @include rem('padding', 34px 0px);
        }
    }

    .upsell-subsciptions__sub {
        @include cf;
        @include rem('padding', 21px 21px 14px 21px);
        background: #fafafa;
        border: 1px solid #ccc;

        &:first-child {
            border-bottom:none;
        }

        &:last-child {
            border-top:none;
        }

        .top-section {
            @include cf;
            @include rem('padding-bottom', 12px);
        }

        @include breakpoint($bp-medium) {
            @include rem('padding', 21px);
        }
    }

    .sub__name {
        @include rem('font-size', 13px);

        @include breakpoint($bp-medium) {
            @include rem('line-height', 35px);
            width: 20%;
            float: left;
            text-align: left;
        }

        @include breakpoint($bp-large) {
            @include rem('font-size', 20px);
        }
    }

    .sub__price {
        @include rem('font-size', 13px);
        @include rem('margin-top', 8px);

        @include breakpoint($bp-medium) {
            @include rem('font-size', 19px);
            @include rem('line-height', 35px);
            width: 25%;
            float: left;
            text-align: left;
            margin: 0;

            span {
                @include rem('font-size', 13px);
            }
        }

        @include breakpoint($bp-large) {
            @include rem('font-size', 22px);

            span {
                @include rem('font-size', 18px);
            }
        }
    }

    .sub__reports{
        @include breakpoint($bp-medium) {
            @include rem('line-height', 35px);
            width: 35%;
            float: left;
            text-align: left;
        }

        @include breakpoint($bp-large) {
            @include rem('font-size', 18px);
        }
    }

    .plan {
        @include rem('margin', 0px 7px);

        @include breakpoint($bp-medium) {
            width: 97%;
            margin: 0 auto;
        }
    }

    .highlighted-plan {
        @include rem('padding', 21px 28px 14px 28px);
        background: #f1fbf1;
        border: 1px solid #00b200;
        margin: -1px 0;
        z-index: 1;
        position: relative;

        @include breakpoint($bp-medium) {
            @include rem('padding', 21px 32px);
        }

        @include breakpoint($bp-large) {
            @include rem('padding', 21px 35px);
        }
    }

    .price {
        float: left;
        width: 50%;
        text-align: left;
        color: #525965;
    }

    .sub-buy-now-button {
        @include rem('margin-top', 7px);
        @include rem('padding', 4px 20px);
        border: 1px solid $primary;
        float: right;

        @include breakpoint($bp-medium) {
            margin-top: 0;
        }

        &.btn-style-green {
            border: none;
        }

        &.disabled {
            opacity: 0.6;
            cursor: default;
        }
    }

    hr {
        border: 0;
        height: 1px;
        background: #ccc;
    }

    .credit-report-limit {
        @include rem('margin-top', 12px);
        @include rem('font-size', 13px);
    }
}

.blue-why-section {
    @include cf;
    @include rem('font-size', 15px);
    @include rem('padding', 32px 18px 34px);
    background: #343d4d;
    color: white;

    @include breakpoint($bp-medium) {
        @include rem('font-size', 13px);
        @include rem('padding', 32px 88px);
    }

    @include breakpoint($bp-large) {
        @include rem('font-size', 15px);
        @include rem('font-size', 38px 88px);
    }

    p {
        font-weight: 300;
    }

    p:nth-child(2n) {
        @include rem('margin-top', 10px);
    }

    span {
        font-weight: 500;
    }

    a {
        color: white;
        text-decoration: underline;
    }
}

//////// Advert Upsell ////////

.advert-upsell-popup {
    @extend %upsell-popup;
    @include rem('max-width', 370px);

    @include breakpoint($bp-medium) {
        @include rem('max-width', 700px);
    }

    @include breakpoint($bp-large) {
        @include rem('max-width', 850px);
    }
}

.advert-upsell-subscriptions {
    @extend %upsell-popup__top-section--plan-table;

    .more-info-link {
        @include rem('font-size', 13px);
        text-decoration: underline;
        color: #6d737c;

        @include breakpoint($bp-large) {
            @include rem('font-size', 15px);
        }
    }
}

//////// Subscribe Report Upsell ////////

.buy-subscription-popup {
    @extend %upsell-popup;
    @include rem('max-width', 370px);

    @include breakpoint($bp-medium) {
        @include rem('max-width', 700px);
    }

    @include breakpoint($bp-large) {
        @include rem('max-width', 950px);
    }
}

.subscribe-report-upsell {
    @extend %upsell-popup__top-section--plan-table;
    padding-bottom: 0px;
}

h2.report-upsell__header {
    @include rem('padding-bottom', 15px);
}

.report-upsell__single-report-text {
    @include rem('padding-bottom', 20px);
    @include rem('max-width', 250px);
    margin: 0 auto;

    @include breakpoint($bp-medium) {
        @include rem('font-size', 18px);
        @include rem('padding', 14px 0px);
        max-width: initial;
        float: left;
    }

    @include breakpoint($bp-large) {
        @include rem('font-size', 23px);
        @include rem('padding', 21px 0px);
    }
}

.blue-why-section .report-upsell__single-report-button {
    @extend .btn-style-1;
    @extend .btn-style-green;
    text-decoration: none;

    @include breakpoint($bp-medium) {
        @include rem('font-size', 16px);
        @include rem('padding', 15px 26px);
        float: right;
    }

    @include breakpoint($bp-large) {
        @include rem('font-size', 24px);
        @include rem('padding', 20px 46px);
    }
}

.report-upsell__blue-section {
    @include rem('padding', 25px);

    @include breakpoint($bp-medium) {
        @include rem('padding', 35px);
    }

    @include breakpoint($bp-large) {
        @include rem('padding', 45px);
    }
}

.subscribe-report-upsell .upsell-subscriptions {
    position: relative;
    padding-top: 0px;
    overflow-y: scroll;
    height: 220px;

    @include breakpoint($bp-medium) {
        height: initial;
        overflow: auto;
    }

    @include breakpoint($bp-large) {
        @include rem('padding', 45px 0px);
    }
}

.upsell-subscriptions-grad {
    content:'';
    height: 40px;
    position: absolute;
    bottom: 0px;
    right: 0px;
    left: 0px;

    -webkit-box-shadow: 0px -40px 20px -10px rgba(255,255,255,1) inset;
    -moz-box-shadow: 0px -40px 20px -10px rgba(255,255,255,1) inset;
    box-shadow: 0px -40px 20px -10px rgba(255,255,255,1) inset;
}

//////// Buy Report Popup ////////

.buy-report-popup {
    @extend .popup;
    @include rem('max-width', 470px);
    @include rem('padding', 35px 20px);

    @include breakpoint($bp-small) {
        @include rem('padding', 44px);
    }
}

.buy-report-popup__header {
    @extend .popup-header;
    text-align: center;
}

.buy-report-popup__single {
    @include rem('padding', 35px 25px 45px);
    @include rem('margin', 30px 0px 50px);
    border: 1px solid $green;
    background: #f2fbf2;
    text-align: center;

    h3 {
        @include rem('font-size', 48px);
        @include rem('margin-bottom', 25px);
        color: #0f8012;
        font-weight: 500;

        span {
            @include rem('font-size', 16px);
            font-weight: 400;
        }
    }

    ul {
        @include rem('margin-left', 20px);
        list-style-image: url('/images/tick_icon.svg');
        text-align: left;
    }

    ul li {
        @include rem('line-height', 20px);

        @include breakpoint($bp-small) {
            @include rem('line-height', 30px);
        }
    }

    a {
        @extend .btn-style-1;
        @extend .btn-style-green;
        @include rem('margin-top', 40px);
        @include rem('padding', 12px 50px);
    }

    @include breakpoint($bp-small) {
        @include rem('padding', 35px 40px 45px);
    }
}

.buy-report-popup__upsell {
    @include cf;
    @include rem('padding', 40px);
    background: #fafafa;
    border: 1px solid #d5d5d5;
    position: relative;
}

.buy-report-popup__upsell-credit-reports {
    @include rem('font-size', 20px);
}

.buy-report-popup__upsell-credit-price {
    @include rem('font-size', 28px);

    span {
        @include rem('font-size', 16px);
    }
}

.buy-report-popup__upsell-button {
    @extend .btn-style-1;
    @include rem('font-size', 14px);
    @include rem('padding', 8px 36px);
}

.buy-report-popup__upsell-pill {
    @include rem('padding', 7px 30px);
    @include rem('width', 185px);
    position: absolute;
    border: 1px solid $primary;
    font-weight: 500;
    color: $primary;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    border-radius: 20px;
    background: white;
    top: -16px;
}

.buy-report-popup__upsell-left {
    text-align: center;

    @include breakpoint($bp-small) {
        text-align: left;
        width: 50%;
        float: left;
    }
}

.buy-report-popup__upsell-right {
    @include rem('margin-top', 20px);
    text-align: center;

    @include breakpoint($bp-small) {
        width: 50%;
        float: right;
        text-align: right;
    }
}

.buy-report-popup__footnote {
    @include rem('margin-top', 25px);
    text-align: center;
}

//////// Unlock Confirm Popup ////////

.unlock-confirm-popup {
    @extend %upsell-popup;
    @include rem('max-width', 520px);

    @include breakpoint($bp-medium) {
        @include rem('max-width', 660px);
    }
}

.unlock-section {
    @extend %upsell-popup__top-section;

    .unlock-button {
        @extend %upsell-popup__button;
    }

    .get-more-reports-link {
        text-decoration: underline;
        color: #000000;
    }

    .unlock-icon {
        @include rem('width', 44px);
    }
}

//////// Session limit popups ////////

.session-limit-popup {
    @extend %upsell-popup;
    @include rem('max-width', 520px);

    @include breakpoint($bp-medium) {
        @include rem('max-width', 660px);
    }
}

.session-limit-section {
    @extend %upsell-popup__top-section;

    .upgrade-button{
        @extend %upsell-popup__button;
    }

    .limit-reached-icon {
        @include rem('width', 90px);
    }
}

//////// Downgrade Popups ////////

.downgrade-subscription-popup {
    @extend %upsell-popup;
    @include rem('max-width', 370px);

    @include breakpoint($bp-medium) {
        @include rem('max-width', 700px);
    }

    @include breakpoint($bp-large) {
        @include rem('max-width', 850px);
    }
}

.downgrade-plans-section {
    @extend %upsell-popup__top-section--plan-table;

    .suitable-subheader {
        @include rem('margin-top', 20px);
    }
}

.downgrade-reason-popup {
    @extend %upsell-popup;
    @include rem('max-width', 520px);

    @include breakpoint($bp-medium) {
        @include rem('max-width', 610px);
    }
}

.reason-section {
    @extend %upsell-popup__top-section;

    .downgrade-reasons {
        margin: 30px auto 15px;
        text-align: left;

        @include breakpoint($bp-small) {
            width: 80%;
        }
    }

    .downgrade-reason {
        @include rem('margin-bottom', 8px);
        display: block;

        input {
            float: left;
        }

        label {
            @include rem('margin-left', 25px);
            display: block;
        }
    }
}

.downgrade-confirm-popup {
    @extend %upsell-popup;
    @include rem('max-width', 520px);

    @include breakpoint($bp-medium) {
        @include rem('max-width', 580px);
    }
}

.improve-section {
    @extend %upsell-popup__top-section;

    .improve-subheader{
        width: 100%;
    }

    .downgrade-improve-form {
        @include rem('margin-top', 30px);
    }

    .downgrade-improve__textarea {
        display: block;
        width: 100%;
        @include rem('height', 150px);
        @include rem('margin-bottom', 20px);
        @include rem('padding', 15px);
        border: 1px solid #bbb;
        border-radius: 6px;
    }

    .cancel-subscription-button, .continue-subscription-button {
        @include rem('width', 200px);
        @include rem('padding', 10px 0px);
        margin: 0 auto;
        display: block;
        border-radius: 4px;

        @include breakpoint($bp-small) {
            display: inline-block;
        }
    }

    .cancel-subscription-button {
        @include rem('margin-bottom', 15px);
        background: #D04A17;
        color: white;
        border: none;

        @include breakpoint($bp-small) {
            @include rem('margin-right', 10px);
        }
    }

    .continue-subscription-button {
        background: white;
        border: 1px solid #C2C4C9;

        @include breakpoint($bp-small) {
            @include rem('margin-left', 10px);
        }
    }
}

.downgrade-discount-popup {
    @extend %upsell-popup;
    border-radius: 4px;

    .discount-top {
        @include rem('padding', 40px 30px);
        text-align: center;

        h2 {
            @extend .popup-header;
        }

        p {
            color: #6D737B;
        }
    }

    .discount-bottom {
        @include rem('padding', 40px);
        background: #F5F6F9;
        border: 1px solid #ccc;

        h2 {
            @include rem('font-size', 44px);
            color: #19B01D;
        }

        h3 {
            @include rem('font-size', 34px);
            font-weight: 300;
            color: #19B01D;
        }

        .discount-desc {
            @include rem('line-height', 26px);
            margin: 30px auto;

            @include breakpoint($bp-small) {
                width: 90%;
            }
        }

        .choose-plan-button {
            @include rem('padding', 12px 28px);
            @include rem('margin-bottom', 30px);
        }

        .no-thanks-link {
            display: block;
            color: #9DA1A8;
            text-decoration: underline;
        }
    }
}

.downgrade-confirm-plan-popup, .upgrade-confirm-plan-popup{
    @extend %upsell-popup;
    @include rem('max-width', 530px);
}

.downgrade-confirm-section, .upgrade-confirm-section {
    @extend %upsell-popup__top-section;

    .plan-allowances {
        @include rem('margin', 30px);

        li {
            @include rem('font-size', 16px);
        }
    }

    .confirm-desc {
        @include rem('font-size', 16px);
        width: 90%;
        margin: 0 auto;
    }

    .confirm-plan-button {
        @extend .btn-style-1;
        @include rem('margin', 30px 0px);
    }
    .upgrade-confirm-plan-button {
        @extend .confirm-plan-button;
        @extend .btn-style-green;
    }

    .cancel-link {
        @include rem('font-size', 14px);
        display: block;
    }
}

//////// Direct Watch Popups ////////

.watch-email-required-popup {
    @extend %upsell-popup;
    @include rem('max-width', 520px);

    .popup-header {
        @include rem('margin-bottom', 25px);
    }

    @include breakpoint($bp-medium) {
        @include rem('max-width', 600px);
    }
}

.watch-email-required-section {
    @extend %upsell-popup__top-section;
}

.watch-email-input {
    @include rem('height', 45px);
    @include rem('padding', 10px);
    display: block;
    width: 80%;
    border-radius: 4px;
    margin: 35px auto 25px;
    border: 1px solid #d9d9d9;
}

.watch-email-submit {
    @extend .btn-style-1;
    @include rem('padding', 12px 30px);
}

.company-watched-popup {
    @extend %upsell-popup;
    @include rem('max-width', 395px);

    .popup-header {
        @include rem('margin-bottom', 25px);
    }

    @include breakpoint($bp-medium) {
        @include rem('max-width', 540px);
    }
}

.company-watched-section {
    @extend %upsell-popup__top-section;
}

//////// Lite coupon popup ////////

.lite-coupon-popup {
    @extend .popup;
    @include rem('max-width', 380px);
    text-align: center;

    .coupon-section {
        @include cf;
        @include rem('margin', 30px 0px);
        @include rem('padding', 15px 25px);
        border: 1px dashed #d2d2d2;
        border-radius: 4px;

        span {
            @include rem('font-size', 18px);
            @include rem('padding-top', 8px);
            @include rem('margin-bottom', 10px);
            font-weight: 700;
            display: inline-block;

            @include breakpoint($bp-small) {
                float: left;
                margin-bottom: 0px;
            }
        }

        a {
            @extend .btn-style-1;
            @extend .btn-style-green;
            @include rem('padding', 8px 18px);
            @include rem('font-size', 14px);

            @include breakpoint($bp-small) {
                float: right;
            }
        }
    }

    .continue-to-checkout {
        color: $primary;
        text-decoration: none;
    }
}

//////// Renewal Popup ////////

.renewal-popup {
    @extend .popup;
    @include rem('max-width', 600px);
    text-align: center;

    h2 {
        @include rem('font-size', 22px);
        color: $green;
        font-weight: 400;
    }

    p {
        @include rem('margin', 10px 0px 40px);
        opacity: 0.7;
    }

    a {
        @extend .btn-style-1;
        @include rem('padding', 14px 100px);
    }
}

//////// Document Upsell Popup ////////

.document-upsell-popup {
    @extend %upsell-popup;
    @include rem('max-width', 450px);
}

.document-upsell-top-section {
    @extend %upsell-popup__top-section;

    h2 {
        font-weight: 400;
    }
}

.document-upsell-lite-plan {
    @include rem('padding', 30px);
    @include rem('max-width', 325px);
    border: 1px solid #d5d5d5;
    background: #fafafa;
    margin: 0 auto 30px;

    small {
        @include rem('font-size', 10px);
        @include rem('margin-top', 8px);
        color: #525965;
        display: block;
    }
}

p.document-upsell-lite-plan__name {
    @include rem('font-size', 14px);
    @include rem('margin-bottom', 5px);
    color: #525965;
}

p.document-upsell-lite-plan__price {
    @include rem('font-size', 20px);
    @include rem('margin-bottom', 30px);

    span {
        @include rem('font-size', 12px);
    }
}

p.document-upsell-lite-plan__desc {
    @include rem('font-size', 20px);
    @include rem('margin-bottom', 10px);

    @include breakpoint($bp-medium) {
        @include rem('font-size', 22px);
    }
}

.document-upsell-lite-plan__buy {
    @extend .btn-style-1;
    @extend .btn-style-green;
    @include rem('margin-top', 30px);
    @include rem('padding', 9px 39px);
}

.document-upsell-lite-plan__compare {
    @include rem('font-size', 15px);
    text-decoration: underline;
    font-weight: 300;
    color: $dark-text;
}

.document-upsell-lite-plan__show {
    @include rem('font-size', 14px);
    font-weight: 300;
}

#dont-show-again {
    @include rem('margin-right', 5px);
}
