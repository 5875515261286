.result__status {
    @include rem('font-size', 11px);
    @include rem('padding', 2px 6px 2px 0px);
    display: inline-block;
    text-transform: uppercase;
    position: relative;
    top: -1px;

    &--dissolved {
        color: $red;
    }
}

.company__status {
    color: white;
    font-weight: bold;
    display: inline-block;
    text-transform: uppercase;
    text-align: center;
    vertical-align: top;
    @include rem('font-size', 10px);
    @include rem('margin-top', 2px);
    @include rem('padding-top', 2px);
    @include rem('padding-bottom', 2px);
    @include rem('padding-right', 3px);
    @include rem('padding-left', 3px);
    @include rem('line-height', 11px);
    @include breakpoint($bp-medium) {
        margin-top: 0;
        vertical-align: middle;
        @include rem('padding-top', 4px);
        @include rem('padding-bottom', 4px);
        @include rem('padding-right', 4px);
        @include rem('padding-left', 4px);
        @include rem('line-height', 11px);
    }
    @include breakpoint($bp-large) {
        @include rem('line-height', 11px);
    }
    &-active {
        background: #00b800;
    }
    &-dissolved {
        background: #d94141;
    }
}
