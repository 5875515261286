.mobile-dropdown-menu-container {
    @include rem('max-width', 400px);
    position: relative;
    margin: 0 auto;
}

.mobile-dropdown-menu {
    position: relative;
    text-align:center;
    background: transparent;
    border: none;
    display: block;
    width:100%;
    @include rem('padding', 0px 15px);
    @include rem('line-height', 41px);
    @include rem('font-size', 13px);
    @include rem('height', 41px);
    &:focus {
        box-shadow: 0 0 0 1pt #d9d9d9;
        outline: none;
    }
    -webkit-appearance: none;
    box-shadow: 0 0 0 1pt #d9d9d9;
    z-index:2;
    option {
        color:black;
    }
}

.dropdown-arrows {
    @include rem('height', 41px);
    @include rem('width', 34px);
    background: url('/images/updown-tab-arrows.svg') #d9d9d9 no-repeat center center;
    background-size: auto 17px;
    position: absolute;
    top:0;
    bottom:0;
    right:0;
    z-index:1;

    -webkit-border-top-right-radius: 4px;
    -webkit-border-bottom-right-radius: 4px;
    -moz-border-radius-topright: 4px;
    -moz-border-radius-bottomright: 4px;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
}
