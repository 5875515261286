.search-box form {

    position: relative;
    height: 32px;
    margin-bottom: 0;

    input[type="search"] {
        @include rem('padding-left', 8px);
        @include rem('padding-right', 38px);
        width: 100%;
        height: 100%;
        display: block;
        border: 1px solid #d5d5d5;
        -webkit-border-radius: 4px;
        -moz-border-radius: 4px;
        border-radius: 4px;
        font-size: 0.9em;
        box-sizing: border-box;
        color: $dark-text;
    }

    .search-button {

        background: url('/images/search.svg') no-repeat center,
                    #3775d0;
        background-size: 38%;

        border: 0;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        padding: 0;
        @include rem('width', 30px);

        -webkit-border-top-right-radius: 4px;
        -webkit-border-bottom-right-radius: 4px;
        -moz-border-radius-topright: 4px;
        -moz-border-radius-bottomright: 4px;
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
    }
}

.floating-header .search-box form {

    @include breakpoint($bp-medium) {
        input[type="search"] {
            background: rgba(255,255,255,0.1);
            border: 1px solid #74716e;
            color: white;
        }
    }

    @include breakpoint($bp-medium) {
        .search-button {
            background: url('/images/search.svg') no-repeat center,
                        rgba(255,255,255,0.2);
            background-size: 38%;
            border: 1px solid #74716e;
        }
    }
}
