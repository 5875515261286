.site-footer--sticky {
    position: absolute;
    right: 0;
    left: 0;
    bottom: 0;
}

.footer__body {
    @include rem('padding', 40px 0px 22px);
    background: #283039;
    text-align: center;
    color: #b6babd;

    .container {
        @include rem('max-width', 365px);

        @include breakpoint($bp-small) {
            @include rem('max-width', 490px);
        }

        @include breakpoint($bp-medium) {
            max-width: $bp-large;
        }
    }

    @include breakpoint($bp-large) {
        @include rem('padding', 70px 0px 60px);
    }
}

%footer__header {
    @include rem('font-size', 11px);
    @include rem('margin-bottom', 25px);
    color: white;
    text-transform: uppercase;
    font-weight: 400;

    @include breakpoint($bp-medium) {
        @include rem('font-size', 13px);
    }
}

.footer__as-featured {
    @extend %footer__header;
}

.footer__featured-logos {
    @include rem('padding-bottom', 20px);

    li {
        @include rem('margin', 0px 9px 25px);
        display: inline-block;

        img {
            width: 100%;
            vertical-align: middle;
        }

        @include breakpoint($bp-medium) {
            width: inherit;
            height: inherit;
        }

        @include breakpoint($bp-large) {
            @include rem('margin', 7px 14px 60px);

            &:first-child {
                margin-left: 0;
            }

            &:last-child {
                margin-right: 0;
            }
        }
    }
}

.business-zone {
    @include rem('width', 80px);
    @include rem('height', 10px);
}

.cityam {
    @include rem('width', 68px);
    @include rem('height', 15px);
}

.elite-business {
    @include rem('width', 101px);
    @include rem('height', 14px);
}

.free-business-thinking {
    @include rem('width', 60px);
    @include rem('height', 20px);
}

.lte-logo {
    @include rem('width', 86px);
    @include rem('height', 20px);
}

.minutehack {
    @include rem('width', 88px);
    @include rem('height', 10px);
}

.footer__company-info {
    @include rem('margin-bottom', 40px);

    img {
        @include rem('width', 144px);
        @include rem('height', 18px);
        @include rem('margin-bottom', 20px);

        @include breakpoint($bp-medium) {
            @include rem('width', 170px);
            @include rem('height', 22px);
        }
    }

    p {
        @include rem('font-size', 13px);
        @include rem('line-height', 21px);
        font-weight: 300;

        @include breakpoint($bp-medium) {
            @include rem('font-size', 15px);
            @include rem('line-height', 26px);
        }
    }

    @include breakpoint($bp-medium) {
        @include rem('max-width', 500px);
        margin-right: auto;
        margin-left: auto;
    }

    @include breakpoint($bp-large) {
        @include rem('width', 275px);
        float: left;
        text-align: left;
    }
}

.footer__nav {
    @include cf;

    @include breakpoint($bp-medium) {
        @include rem('max-width', 500px);
        margin: 0 auto;
    }

    @include breakpoint($bp-large) {
        @include rem('width', 355px);
        @include rem('margin-left', 90px);
        float: left;
        text-align: left;
    }
}

.footer__services-nav {
    width: 50%;
    float: left;
}

.footer__useful-nav {
    width: 50%;
    float: right;
}

.footer__nav-header {
    @extend %footer__header;
}

.footer__nav-links {
    li {
        @include rem('margin-bottom', 20px);
        font-weight: 300;

        @include breakpoint($bp-large) {
            @include rem('font-size', 15px);
            @include rem('line-height', 26px);
            margin: 0;
        }
    }

    a {
        color: #b6babd;
        text-decoration: none;
    }
}

.footer__news-and-social {
    @include breakpoint($bp-large) {
        @include rem('width', 290px);
        float: right;
        text-align: left;
    }
}

.footer__newsletter {
    @include rem('padding', 25px 0px 45px);

    @include breakpoint($bp-large) {
        @include rem('padding', 0px 0px 35px);
    }
}

.footer__newsletter-header {
    @extend %footer__header;
}

.footer__newsletter-form {
    @include rem('font-size', 13px);
    @include rem('max-width', 330px);
    position: relative;
    margin: 0 auto;
}

input[type='email'].newsletter-form__email {
    @include rem('height', 40px);
    @include rem('padding', 0px 110px 0px 16px);
    width: 100%;
    border: none;
    border-radius: 4px;
    color: $dark-text;
}

input[type='submit'].newsletter-form__submit {
    @include rem('padding', 0px 24px);
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    background: $green;
    color: white;
    border: none;

    -webkit-border-top-right-radius: 4px;
    -webkit-border-bottom-right-radius: 4px;
    -moz-border-radius-topright: 4px;
    -moz-border-radius-bottomright: 4px;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
}

.footer__social-header {
    @extend %footer__header;
}

.footer__social-buttons {
    li {
        @include rem('margin', 0px 11px 22px);
        display: inline-block;

        @include breakpoint($bp-large) {
            @include rem('margin', 0px 12px 12px 0px);
        }
    }
}

.footer__footer {
    @include rem('padding', 40px 0px 45px);
    @include rem('font-size', 14px);
    @include rem('line-height', 22px);
    background: #242B33;
    text-align: center;
    color: white;

    & > * {
        opacity: 0.5;
    }

    .container {
        @include rem('max-width', 340px);

        @include breakpoint($bp-small) {
            @include rem('max-width', 420px);
        }

        @include breakpoint($bp-medium) {
            max-width: $bp-large;
        }
    }

    @include breakpoint($bp-large) {
        @include rem('font-size', 15px);
    }
}

.footer__footer-links {
    @include rem('margin-top', 25px);

    li {
        @include rem('line-height', 22px);
        display: inline;

        &:after {
            @include rem('margin', 0px 10px 0px 12px);
            content: "|";
        }

        &:last-child:after {
            content: "";
        }
    }

    li a {
        text-decoration: none;
        color: white;
    }
}
