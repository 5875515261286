.btn-style-1 {
    background: #3775d0;
    border: none;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px !important;
    color: #ffffff;
    padding: 12px 20px;
    text-decoration: none;
    display: inline-block;
    font-weight: 300;
}

.btn-style-2 {
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    line-height: 27px;
    padding: 0 8px;
    text-decoration: none;
    display: inline-block;
    font-weight: 400;
    @include rem('font-size', 12px);
    @include breakpoint($bp-medium) {
        @include rem('font-size', 13px);
    }
    @include breakpoint($bp-large) {
        font-weight: 500;
        @include rem('font-size', 13px);
    }
}

.btn-style-3 {
    font-weight: normal;
    color: #d7e3f5;
    text-align: center;
    background-color: #3775d0;
    border-radius: 4px;
    text-decoration: none;
    display: inline-block;
    @include rem('line-height', 31px);
    @include rem('height', 31px);
    @include rem('font-size', 12px);
    @include breakpoint($bp-large) {
        @include rem('height', 36px);
        @include rem('line-height', 36px);
        @include rem('font-size', 14px);
    }
    span {
        float: right;
        @include rem('width', 21px);
        @include rem('height', 31px);
        @include breakpoint($bp-large) {
            @include rem('height', 36px);
            @include rem('width', 25px);
        }
        &:after {
            content: '';
            opacity: 0.8;
            display: block;
            border-left: 4px solid transparent;
            border-right: 4px solid transparent;
            border-top: 6px solid #ffffff;
            float: right;
            @include rem('margin-top', 13px);
            @include rem('margin-right', 7px);
            @include breakpoint($bp-large) {
                @include rem('margin-top', 15px);
                @include rem('margin-right', 9px);
            }
        }
    }
}

.btn-style-green {
    background: #00b200;
    color: #ffffff;
    span {
        border-left: 1px solid #009900;
    }
}

.btn-style-stars {
    @include rem('line-height', 30px);
    @include rem('height', 30px);
    position: relative;
    font-weight: normal;
    background: #eff2f5;
    text-align: center;
    border: 1px solid #d2d2d2;
    border-radius: 4px;
    text-decoration: none;
    display: inline-block;
    @include breakpoint($bp-medium) {
        border: 1px solid #dadada;
    }
    @include breakpoint($bp-large) {
        @include rem('height', 36px);
        @include rem('line-height', 36px);
    }
    span {
        @include rem('line-height', 30px);
        @include rem('width', 32px);
        @include rem('height', 30px);
        @include rem('font-size', 14px);
        position: absolute;
        top: 0;
        right: 0;
        color: #ffffff;
        margin-top: -1px;
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
        @include breakpoint($bp-medium) {
            @include rem('font-size', 16px);
        }
        @include breakpoint($bp-large) {
            @include rem('height', 36px);
            @include rem('width', 34px);
            @include rem('line-height', 36px);
            @include rem('font-size', 18px);
        }
    }
    .score_green {
        background: #00b200;
        border-left: 1px solid #009900;
    }
    .score_yellow {
        background: #d2ac3a;
        border-left: 1px solid #d2ac3a;
    }
    .score_red {
        background: #d94141;
        border-left: 1px solid #d94141;
    }
    .score_not-set {
        background: #C6C6C6;
        border-left: 1px solid #C6C6C6;
    }
    ul {
        @include rem('padding', 7px 38px 7px 5px);
        list-style-type: none;
        &>li {
            @include rem('height', 12px);
            @include rem('width', 12px);
            @include rem('margin', 0px 2px);
            @include rem('padding', 0px 7px);
            background: url(/images/company/star-full.svg) no-repeat;
            float: left;
            @include breakpoint($bp-medium) {
                @include rem('height', 15px);
                @include rem('width', 15px);
            }
            @include breakpoint($bp-large) {
                @include rem('height', 16px);
                @include rem('width', 16px);
            }
        }
        @include breakpoint($bp-medium) {
            @include rem('padding', 6px 38px 6px 5px);
        }
        @include breakpoint($bp-large) {
            @include rem('padding', 8px 38px 8px 5px);
        }
    }
    & li.active~li {
        background: url(/images/company/star-empty.svg) no-repeat;
    }
    .inactive {
        display: none;
    }
}

.btn-style-blue {
    background: #3775d0;
    color: #ffffff;
    span {
        border-left: 1px solid #2f64b3;
    }
}

.btn-style-grey {
    background: #3775d0;
    background-color: #747474;
    color: #ffffff;
    span {
        border-left: 1px solid #d9d9d9;
    }
    .active-folder {
        background: url(/images/tick_icon.svg) 10px center no-repeat;
        background-size: 10px 10px;
    }
}

.btn-style-4>img {
    height: 21px;
    width: 21px;
}

.btn-style-5 {
    @include rem('padding', 5px 25px);
    background: #fff;
    border: 1px solid #c9c9c9;
    color: #454545;
    text-transform: uppercase;
    text-decoration: none;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
}

.btn-style-6 {
    border: #c9c9c9 1px solid;
    color: #5d5d5d;
    background-color: #f5f5f5;
    text-align: center;
    border-radius: 4px;
    text-decoration: none;
    display: inline-block;
    line-height: 35px;
    @include rem('font-size', 13px);
    @include breakpoint($bp-large) {
        @include rem('font-size', 14px);
    }
    .btn-style-6--label {
        @include rem('padding', 0px 8px);
        display: inline-block;
        border-right: 1px solid #c9c9c9;
    }
    img {
        @include rem('width', 9px);
        @include rem('margin', 0px 8px 0px 5px);
    }
}

.btn-style-7 {
    @include rem('padding', 0px 22px);
    border: #c9c9c9 1px solid;
    color: #5d5d5d;
    background-color: #f5f5f5;
    text-align: center;
    border-radius: 4px;
    text-decoration: none;
    display: inline-block;
    &.disabled {
        background: #dcdcdc;
    }
}

.btn-style-8 {
    @include rem('padding', 9px 12px);
    @include rem('font-size', 9px);
    font-weight: 500;
    border: #c9c9c9 1px solid;
    color: #5d5d5d;
    background-color: #fff;
    text-align: center;
    border-radius: 4px;
    text-decoration: none;
    text-transform: uppercase;
    display: inline-block;
}

.btn-style-9 {
    @include rem('padding', 12px 30px);
    @include rem('font-size', 13px);
    border: #818998 1px solid;
    color: #fff;
    background: rgba(255, 255, 255, 0.1);
    text-align: center;
    border-radius: 4px;
    text-decoration: none;
    display: inline-block;
}

.btn-style-10 {
    font-weight: 500;
    @include rem('padding', 10px 20px);
    @include rem('font-size', 16px);
    display: block;
    border: #9bbae7 1px solid;
    color: #3775d0;
    background: white;
    text-align: center;
    border-radius: 30px;
    text-decoration: none;
    display: inline-block;
}

.btn-documents {
    text-align: center;
    font-size: 13px;
    display: inline-block;
    float: left;
    border-radius: 4px;
    width: 100px;
    height: 27px;
    line-height: 27px;
    color: #ffffff;
    @include breakpoint($bp-medium) {
        float: right;
    }
    &--add {
        background: #00b200;
    }
    &--added,
    &--ordered {
        background: #c7c7c7;
    }
    &--disabled {
        background: #c7c7c7;
    }
    &--remove {
        background: #b23200;
    }
    &--download {
        background: #3775d0;
    }
    &--filter {
        background: #878787;
        width: 117px;
        margin-top: 12px;
        margin-bottom: 24px;
    }
}

.btn-filters {
    font-size: 10px;
}

.btn-documents-filter {
    text-align: center;
    display: block;
    clear: left;
    border-radius: 4px;
    width: 100px;
    height: 27px;
    line-height: 27px;
    color: #ffffff;
    @include breakpoint($bp-medium) {
        float: right;
    }
    background: #878787;
}

.buy-now-button,
.upgrade-now-button {
    text-align: center;
    font-size: 13px;
    display: inline-block;
    border-radius: 4px;
    width: 100px;
    height: 27px;
    line-height: 27px;
    color: #ffffff;
    background: #00b200;
}

.upgrade-now-button {
    @extend .buy-now-button;
    margin-left: 25%;
}

.upsell-buy-button {
    @extend .btn-style-1;
    background: #00b200;
    padding: 12px 31px;
}

.director-companies-button {
    @include rem('padding', 5px 5px);
    background: #f9f9f9;
    border: 1px solid #c9c9c9;
    color: #454545;
    text-decoration: none;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
}

.locked-section-upsell-button {
    position: absolute;
    top: -8px;
    right: 0;
}

.news-bar .cookie-close-button {
    position: relative;
    display: inline-block;
    text-decoration: none;
    text-transform: uppercase;
    background: rgba(0, 0, 0, 0.1);
    border: 1px solid white;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    border-radius: 6px;
    margin-top: 10px;
    padding: 6px 20px 6px 35px;
    font-size: 12px;
    @include breakpoint($bp-medium) {
        padding: 6px 20px 6px 38px;
        margin-top: 5px;
        font-size: 13px;
    }
    &:before {
        content: url('/images/close_icon.svg');
        position: absolute;
        left: 15px;
        height: 10px;
        width: 10px;
        @include breakpoint($bp-large) {
            height: 11px;
            width: 11px;
        }
    }
}

.share-buttons__dropdown {
    background: white;
    position: absolute;
    z-index: 3;
    left: 31%;
    border: 1px solid #c8c8c8;
    box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.27);
    @include breakpoint($bp-small) {
        left: 39%;
    }
    @include breakpoint($bp-medium) {
        transform: rotate(90deg);
        top: 3px;
        left: -6px;
    }
    @include breakpoint($bp-large) {
        top: 6px;
        left: 0px;
    }
    ul>li {
        margin: 15px 20px 15px 20px;
        float: none !important;
        &:first-child {
            margin-top: 20px;
        }
        @include breakpoint($bp-medium) {
            float: inherit;
            margin: 14px 18px 14px 18px;
            &:first-child {
                margin-top: 18px;
            }
        }
        img {
            width: 86px;
            height: 24px;
            @include breakpoint($bp-medium) {
                width: 90px;
                height: 26px;
            }
            @include breakpoint($bp-large) {
                width: 86px;
                height: 24px;
            }
        }
    }
}

span.badge-pill {
    width: 50px;
    text-align: center;
    font-weight: bold;
    border-radius: 50px;
}

span.badge-rounded {
    width: 50px;
    font-weight: bold;
    padding: 5px;
    border-radius: 20px;
}

span.overlay-top-right {
    width: 30px !important;
    z-index: 1;
    position: absolute !important;
    font-size: 0.6rem !important;
    margin-left: 3px;
    margin-top: -15px;
}

span.danger {
    background-color: red;
    color: white;
}