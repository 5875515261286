.login-container {
    @include rem('max-width', 460px);
    @include rem('padding-bottom', 40px);
    margin: 0 auto;

    background: #fff;

    @include breakpoint($bp-small) {
        background: transparent;
    }

    @include breakpoint($bp-large) {
        @include rem('max-width', 524px);
        @include rem('padding-bottom', 68px);
    }
    &--message {
        color: #008100;
        font-size: 17px;
        line-height: 26px;
        background-color: #f2fbf2;
        padding: 33px 32px;
        border: 1px solid #00b200;
        margin-bottom: 44px;
    }
}

.login-container__top {
    background: #fff;

    @include breakpoint($bp-small) {
        @include rem('margin-top', 40px);
        -webkit-box-shadow: 1px 1px 5px 0px rgba(50, 50, 50, 0.14);
        -moz-box-shadow:    1px 1px 5px 0px rgba(50, 50, 50, 0.14);
        box-shadow:         1px 1px 5px 0px rgba(50, 50, 50, 0.14);
    }

    @include breakpoint($bp-large) {
        @include rem('margin-top', 68px);
    }
}

.login-container__bottom {
    background: #fff;

    @include breakpoint($bp-small) {
        @include rem('margin-top', 17px);
        -webkit-box-shadow: 1px 1px 5px 0px rgba(50, 50, 50, 0.14);
        -moz-box-shadow:    1px 1px 5px 0px rgba(50, 50, 50, 0.14);
        box-shadow:         1px 1px 5px 0px rgba(50, 50, 50, 0.14);
    }
}

.login-content-container {
    overflow:auto;
    @include rem('padding', 0px 20px);

    @include breakpoint($bp-small) {
        @include rem('padding', 0px 40px);
    }
}

.login-header {
    @extend .popup-header;
    @include rem('padding', 28px 0px 26px);
}

.login__form-section {

    input[type="text"],
    input[type="email"],
    input[type="password"] {
        @include rem('height', 44px);
        @include rem('padding', 8px 8px 8px 42px);
        width: 100%;
        background: #f3f3f3;
        border: 1px solid #d2d2d2;
        -webkit-border-radius: 4px;
        -moz-border-radius: 4px;
        border-radius: 4px;

        @include breakpoint($bp-small) {
            @include rem('height', 56px);
        }

        @include breakpoint($bp-large) {
            @include rem('height', 66px);
            @include rem('font-size', 20px);
            @include rem('padding', 8px 8px 8px 60px);
        }
    }

    input[type="text"].error,
    input[type="email"].error,
    input[type="password"].error {
        border: 1px solid #ff6565;
    }

    input[type="text"].error:focus,
    input[type="email"].error:focus,
    input[type="password"].error:focus {
        border: 1px solid #d2d2d2;
    }

    .error-message {
        @include rem('font-size', 12px);
        font-weight: 500;
        color: #d03737;

        @include breakpoint($bp-small) {
            @include rem('font-size', 14px);
            font-weight: 400;
        }

        @include breakpoint($bp-large) {
            @include rem('font-size', 16px);
        }
    }

    .login-form__email-input, .login-form__password-input, .login-form__name-input, .login-form__firstName-input, .login-form__surname-input, .login-form__postcode-input, .login-form__company-input, .login-form__role-input, .login-form__type-input {
        position: relative;
        @include rem('padding-bottom', 14px);

        @include breakpoint($bp-small) {
            @include rem('padding-bottom', 12px);
        }
    }

    .login-form__name-input, .login-form__firstName-input, .login-form__surname-input, .login-form__postcode-input, .login-form__company-input, .login-form__role-input, .login-form__type-input {
        @include rem('font-size', 14px);
        @include rem('height', 60px);

        input {
            @include rem('height', 30px);

            @include breakpoint($bp-small) {
                @include rem('height', 40px);
            }

            @include breakpoint($bp-large) {
                @include rem('height', 50px);
                @include rem('font-size', 14px);
                @include rem('padding', 8px 8px 8px 60px);
            }
        }

    }

    .login-form__email-input, .login-form__company-input {
        clear: both;
    }

    .input-icon {
        position: absolute;
        @include rem('top', 13px);
        @include rem('left', 15px);
        @include rem('bottom', 0px);
        @include rem('height', 16px);
        @include rem('width', 16px);

        @include breakpoint($bp-small) {
            @include rem('top', 19px);
        }

        @include breakpoint($bp-large) {
            @include rem('top', 21px);
            @include rem('left', 22px);
            @include rem('height', 20px);
            @include rem('width', 20px);
        }
    }

    .email-icon {
        background: url('/images/login/email_address.svg') no-repeat center;
    }

    .password-icon {
        background: url('/images/login/password.svg') no-repeat center;
    }

    .login-form__actions {
        @include rem('padding', 6px 0px 36px);
        display: table;

        .forgotten-pword-text {
            @include rem('font-size', 12px);
            font-weight: 500;
            display: table-cell;
            color: #5d6f8a;

            @include breakpoint($bp-small) {
                @include rem('font-size', 14px);
                font-weight: 400;
            }

            @include breakpoint($bp-small) {
                @include rem('font-size', 16px);
            }
        }
        .form-cell {
            display: table-cell;
            &:last-child {
                text-align: right;
            }
        }
        .log-in-button {
            @include rem('width', 122px);
            @include rem('padding', 7px 0px);

            text-align: center;

            @include breakpoint($bp-small) {
                @include rem('font-size', 14px);
                @include rem('width', 164px);
                @include rem('padding', 10px 0px);
            }

            @include breakpoint($bp-large) {
                @include rem('font-size', 16px);
                @include rem('width', 188px);
                @include rem('padding', 13px 0px);
            }
        }

        @include breakpoint($bp-small) {
            @include rem('padding', 10px 0px 28px);
        }

        @include breakpoint($bp-large) {
            @include rem('padding', 12px 0px 32px);
        }
    }

    .login-form {
        @include breakpoint($bp-small) {
            border-bottom: 1px solid #dddddd;
        }
    }
}

.login__social-section {
    border-top: 1px solid #dddddd;
    text-align: center;

    @include rem('padding-top', 29px);
    @include rem('padding-bottom', 40px);

    p {
        @include breakpoint($bp-small) {
            @include rem('margin-top', 8px);
            float: left;
        }

        @include breakpoint($bp-small) {
            @include rem('font-size', 16px);
            @include rem('margin-top', 11px);
        }
    }

    .social-login-links {
        @include rem('padding-top', 27px);

        li {
            @include rem('padding-right', 19px);
            display: inline;

            @include breakpoint($bp-small) {
                @include rem('padding-right', 14px);
            }

            @include breakpoint($bp-large) {
                @include rem('padding-right', 10px);
            }

            &:last-child {
                @include rem('padding-right', 0px);
            }
        }
        img {
            @include rem('height', 32px);
            @include rem('width', 32px);

            @include breakpoint($bp-small) {
                @include rem('height', 36px);
                @include rem('width', 36px);
            }

            @include breakpoint($bp-large) {
                @include rem('height', 44px);
                @include rem('width', 44px);
            }
        }

        @include breakpoint($bp-small) {
            @include rem('padding-top', 0px);
            @include rem('padding-left', 18px);
            float: left;
        }

        @include breakpoint($bp-large) {
            @include rem('padding-left', 20px);
        }
    }

    @include breakpoint($bp-small) {
        border-top: none;
        @include rem('padding-top', 18px);
        @include rem('padding-bottom', 22px);
        @include cf;
    }

    @include breakpoint($bp-large) {
        @include rem('padding-top', 22px);
        @include rem('padding-bottom', 25px);
    }
}

.login__signup-section {
    text-align: center;

    @include rem('padding', 0px 0px 20px);

    .account-text {
        @include rem('font-size', 13px);
        @include rem('line-height', 22px);
        float: none;
        color: #9ba7b9;
            a {
                text-decoration: none;
            }

        @include breakpoint($bp-small) {
            @include rem('font-size', 16px);
            float: left;
        }

        @include breakpoint($bp-large) {
            @include rem('font-size', 18px);
        }
    }

    .signup-link {
        @include breakpoint($bp-large) {
            @include rem('font-size', 18px);
        }
    }

    @include breakpoint($bp-small) {
        @include rem('padding', 20px 50px);
        @include cf;
    }

    @include breakpoint($bp-large) {
        @include rem('padding', 23px 55px);
    }
}

.privacy-statement {
    color: #9ba7b9;
    font-size: 13px;
    margin-bottom: 20px
}
