.New-testimonials {
    @include rem('padding', 50px 0px);
    text-align: center;

    @include breakpoint($bp-medium) {
        @include rem('padding', 70px 0px 80px);
    }

    @include breakpoint($bp-large) {
        @include rem('padding', 80px 0px 100px);
    }

    &--grey {
        background: #f3f5f8;
    }
}

.New-testimonials__header {
    @include rem('margin-bottom', 16px);
    @include rem('font-size', 22px);
    font-weight: 400;

    @include breakpoint($bp-medium) {
        @include rem('font-size', 28px);
    }

    @include breakpoint($bp-large) {
        @include rem('font-size', 32px);
    }
}


.New-testimonials__subheader {
    @include rem('font-size', 15px);
    @include rem('line-height', 24px);
    @include rem('max-width', 600px);
    margin: 0 auto;
    opacity: 0.7;
    font-weight: 300;

    @include breakpoint($bp-medium) {
        @include rem('font-size', 19px);
    }

    @include breakpoint($bp-large) {
        @include rem('font-size', 22px);
        @include rem('line-height', 30px);
        @include rem('max-width', 800px);
    }
}


.New-testimonials__card {
    margin: 40px auto 0px;

    -webkit-box-shadow: 0px 0px 10px 3px rgba(0,0,0,0.1);
    -moz-box-shadow: 0px 0px 10px 3px rgba(0,0,0,0.1);
    box-shadow: 0px 0px 10px 3px rgba(0,0,0,0.1);

    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
}

.New-testimonials__card--layout1 {
    @include rem('max-width', 300px);

    @include breakpoint($bp-medium) {
        @include span(2 of 6);
        max-width: none;
    }

    &:last-child {
        @include breakpoint($bp-medium) {
            @include span(2 of 6 last);
        }
    }
}

.New-testimonials__card--layout2 {
    @include rem('width', 280px);

    @include breakpoint($bp-medium) {
        @include rem('width', 300px);
    }
}

.New-testimonials__card-header {
    @include rem('height', 100px);
    position: relative;
    background: white;

    img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}

.New-testimonials__card-body {
    @include rem('padding', 30px);
    @include rem('font-size', 15px);
    @include rem('line-height', 24px);
    text-align: left;
    font-weight: 300;
    font-style: italic;
    color: #646972;
    background: #f8f8f8;

    p:not(:first-child) {
        @include rem('padding-top', 25px);
    }
}

.New-testimonials__card-text {
    @include breakpoint($bp-medium) {
        column-count: 2;
        column-gap: 45px;
    }
}

.New-testimonials__card-person {
    @include rem('padding-top', 30px);
    font-style: normal;
    color: $dark-text;

    img {
        @include rem('margin-right', 10px);
        float: left;
    }

    p {
        padding: 0;
    }

    p:first-of-type {
        @include rem('font-size', 16px);
        font-weight: 400;
    }

    p:last-child {
        @include rem('font-size', 13px);
        font-weight: 300;
        padding: 0px;
    }

    &--center {
        text-align: center;
    }
}