.pagination {
    @include cf;
    position: relative;
}

.arrows {
    position: relative;
    float: left;

    @include breakpoint($bp-small) {
        position: absolute;
    }
}

.next {
    @include rem('margin-left', 4px);

    @include breakpoint($bp-medium) {
        @include rem('margin-left', 8px);
    }
}

.pages {
    float: right;

    @include breakpoint($bp-medium) {
        float: none;
        text-align: center;
    }
}

.page-box {

    @include rem('height', 23px);
    @include rem('line-height', 23px);
    @include rem('padding', 0px 9px);
    @include rem('font-size', 12px);
    @include rem('margin-bottom', 8px);

    display: inline-block;
    border: 1px solid #c9c9c9;
    text-align: center;
    background: #fff;
    color: #2c2c2c;
    cursor: pointer;

    @include breakpoint($bp-small) {
        margin-bottom: 0;
    }

    @include breakpoint($bp-medium) {
        @include rem('margin-left', 4px);
    }

    &:first-child {
        @include rem('margin-left', 0px);
    }

    a {
        text-decoration: none;
        color: $dark-text;
    }
}

.page-box--current {
    background: #eaf3ff;
}

.page-box--disabled {
    background: #efefef;
    color: #b9b8b8;
    cursor: default;
}

.page-box--ellipsis {
    color: #2c2c2c
}

.company-documents,
.data-table-container,
.documents-order-history {
    .sorting_desc {
        background: url("/images/sort-descending.svg") no-repeat center right 5px;
        background-size: 10px 10px;
    }
    .sorting_asc {
        background: url("/images/sort-ascending.svg") no-repeat center right 5px;
        background-size: 10px 10px;
    }
    .dataTables_paginate {
        margin-top: 10px;
        text-align: center;
        & > .disabled {
            @extend .page-box--disabled;
        }
        & > .previous {
            @extend .page-box;
        }
        & > .next {
            @extend .page-box;
            @extend .next;
        }
        & > a {
            display:block;
            float: left;
        }
        & > span {
            @extend .pages;
            & > a {
                @extend .page-box;
                margin-left:2px;
                margin-right:2px;
            }
            & > span {
                @extend .page-box;
                margin-left:2px;
                margin-right:2px;
            }
            & > .current {
                @extend .page-box--current;
            }
        }
    }
}
