html {
    font-size: $base-font-size;
}

body {
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    color: $dark-text;
}

h1,h2,h3,h4,h5,h6,p {
    margin: 0;
}

.thin {font-weight: 100;}
.light {font-weight: 300;}
.medium {font-weight: 500;}
.bold {font-weight: 700;}
.underline {text-decoration: underline;}
