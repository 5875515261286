.tabs-style-1 {

    display: table;
    width: 100%;

    .tab {
        @include rem('height', 31px);
        @include rem('line-height', 31px);
        @include rem('font-size', 12px);
        display: table-cell;
        width: 50%;
        text-align: center;
        text-transform: uppercase;
        background: #fff;
        color: #454545;
        border: 1px solid #c9c9c9;
        cursor: pointer;

        @include breakpoint($bp-medium) {
            @include rem('height', 25px);
            @include rem('line-height', 25px);
            @include rem('font-size', 10px);
            font-weight: 500;
        }

        @include breakpoint($bp-large) {
            @include rem('height', 27px);
            @include rem('line-height', 27px);
            @include rem('font-size', 11px);
        }
    }

    .tab--selected {
        border: none;
        background: #606b7b;
        color: #fff;
    }

    .tab--first {
        -webkit-border-top-left-radius: 4px;
        -webkit-border-bottom-left-radius: 4px;
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
    }

    .tab--last {
        -webkit-border-top-right-radius: 4px;
        -webkit-border-bottom-right-radius: 4px;
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
    }
}
