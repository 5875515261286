
.container.towers-container {
  max-width: 1420px;
  position: sticky;
  margin: 0 auto;
  padding: 0;
  top: 119px;
}

.towers  {
  position: absolute;
  width: 100%;
}

.streamamp-advert.tower-left {
    left: 0;
    position: absolute;
    padding: 0;
  }

.streamamp-advert.tower-right {
    right: 0;
    position: absolute;
    padding: 0;
  }

@media (max-width: 1380px) {

  .towers-container {
    display: none;
  }

}
